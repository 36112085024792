import { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import classes from './SideBar.module.css';


const SideBar = (props) => {
  // Sidebase open and close state
  const [show, setShow] = useState(false);
  // User SubMenu open and close state
  const [userSubMenuShow, setUserSubMenuShow] = useState(false);
  // Admin SubMenu open and close state
  const [adminSubMenuShow, setAdminSubMenuShow] = useState(false);
  // Super Admin SubMenu open and close state
  const [superAdminSubMenuShow, setSuperAdminSubMenuShow] = useState(false);
  const tenantId = props.tenantId;

  function handleClose () {
    setShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function handleShow () {setShow(true)};

  function toggleUserSubMenu () {
    setUserSubMenuShow(!userSubMenuShow);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleAdminSubMenu () {
    setAdminSubMenuShow(!adminSubMenuShow);
    setUserSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleSuperAdminSubMenu () {
    setSuperAdminSubMenuShow(!superAdminSubMenuShow);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
  }

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow} size="lg" >
        MENU &nbsp; <i className="fa-solid fa-bars fa-xl"></i>
      </Button>
      <div style={{margin:10}}>
        <Badge
          pill
          bg="warning"
          text="dark"
        >
          {props.displayName}
        </Badge>
        &nbsp;
        <Badge
          pill
          bg="info"
          text="dark"
        >
          {props.role}
        </Badge>
      </div>
      <Offcanvas show={show} onHide={handleClose} style={
        {
          backgroundColor:"#044599",
          color: "whitesmoke",
          width: 200
        }
      }>
        <Offcanvas.Header closeButton closeVariant='white'>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className={classes.linkList}>
            <li>
              { props.isOperator ?
                <Link to='/timeInOut'>
                  <Button>
                    Home
                  </Button>
                </Link>
              :
                <Link to='/home'>
                  <Button>
                    Home
                  </Button>
                </Link>
              }
            </li>

            <li>
              { props.isOperator ?
                null
              :
                <Button onClick={toggleUserSubMenu}>
                  User
                </Button>
              }
            </li>
            <Collapse in={userSubMenuShow}>
            <ul>
              <li>
                <Link to='/userprofile'>
                <Button>
                  User Profile
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/timeinout'>
                <Button>
                  Time In/Out
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/viewuploads'>
                <Button>
                  View Uploads
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/fileleaves'>
                <Button>
                  File Leaves
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/userhelp'>
                <Button>
                  Help
                </Button>
                </Link>
              </li>
            </ul>
            </Collapse>

            <li>
              { props.role === "ADMIN"
                ? <Button onClick={toggleAdminSubMenu}>
                    Admin
                  </Button>
                : null
              }
            </li>
            <Collapse in={adminSubMenuShow}>
            <ul>
              <li>
                <Link to={`/audituploads/${tenantId}`}>
                <Button>
                  Audit Uploads
                </Button>
                </Link>
              </li>
              <li>
                <Link to={`/timeinoutreport/${tenantId}`}>
                <Button>
                  Time In/Out Report
                </Button>
                </Link>
              </li>
              <li>
                <Link to={`/usermanagement/${tenantId}`}>
                <Button>
                  Users
                </Button>
                </Link>
              </li>
              <li>
              <li>
                <Link to={`/leavemanagement/${tenantId}`}>
                <Button>
                  Leaves
                </Button>
                </Link>
              </li>
              <Link to={`/manageshiftgroup/${tenantId}`}>
                <Button>
                  Shift Groups
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/managebroadcasts'>
                <Button>
                  Broadcasts
                </Button>
                </Link>
              </li>
              <li>
                <Link to={`/holidaymanagement/${tenantId}`}>
                <Button>
                  Holidays
                </Button>
                </Link>
              </li>
              <li>
                <Link to={`/tenantdetails/${tenantId}`}>
                <Button>
                  Tenant
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/adminhelp'>
                <Button>
                  Help
                </Button>
                </Link>
              </li>
            </ul>
            </Collapse>

            <li>
              { props.role === "SUPERADMIN"
                ? <Button onClick={toggleSuperAdminSubMenu}>
                    Super Admin
                  </Button>
                : null
              }
            </li>
            <Collapse in={superAdminSubMenuShow}>
            <ul>
              <li>
                <Link to='/createtenant'>
                <Button>
                  Create Tenants
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/searchtenant'>
                <Button>
                  Manage Tenants
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/holidaymanagement'>
                <Button>
                  Manage Holidays
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/superadminhelp'>
                <Button>
                  Help
                </Button>
                </Link>
              </li>
            </ul>
            </Collapse>

            <li>
              <Link to='/signout'>
              <Button>
                Sign Out
              </Button>
            </Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default SideBar;
