import MainHeader from '../../../components/navigation/MainHeader';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './ManageShiftGroups.module.css';
import { useEffect, useState } from 'react';
import CustomLoader from '../../../components/ui-common/CustomLoader';
import { firebaseObserver } from '../../../firebaseConfig';
import { useAuthContext } from "../../../components/context/useAuthContext";
import { Fragment } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { listSubcollectionDocuments } from '../../../components/database/tenant/get';
import ShiftGroupButtonCreateModal from '../../../components/functional/shiftgroup/ShiftGroupButtonCreateModal';
import ShiftGroupListEditModal from '../../../components/functional/shiftgroup/ShiftGroupListEditModal';


const ManageShiftGroups = () => {

  const params = useParams();
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [loaderState, setLoaderState] = useState(false);
  const tenantId = params.tenantId;
  const [shiftGroups, setShiftGroups] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role === "SUPERADMIN") {
          setDisplayName(userAuthContext.displayName);
        } else if (userAuthContext.role === "ADMIN"
          && userAuthContext.tenantId === params.tenantId) {
            setDisplayName(userAuthContext.displayName);
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
      console.log(userAuthContext);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        if (data.claims?.role !== "SUPERADMIN" && data.claims?.role !== "ADMIN") {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    getShiftGroups(tenantId);
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, params.tenantId]);

  async function getShiftGroups(tenantId) {
    setLoaderState(true);
    const shiftGroupResult = await listSubcollectionDocuments("shiftGroups", tenantId, "shiftGroups", "shiftGroup", "shiftGroup", "asc");
    setShiftGroups(shiftGroupResult);
    setLoaderState(false);
  }

  // function onSelectYear(holidaysInYear) {
  //   setSelectedYear(holidaysInYear);
  // }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center>
        <h3 style={{marginTop:15}}>Manage Shift Groups</h3>
        <div className={classes.mainInputContainer}>
          <ShiftGroupButtonCreateModal
            displayName={displayName}
            tenantId={tenantId}
          />
          <br></br>
          <br></br>
          <ListGroup>
          {
            shiftGroups.map((shiftGroup) => (
              <Fragment key={shiftGroup.shiftGroup}>
                <ShiftGroupListEditModal
                  shiftGroup={shiftGroup}
                  displayName={displayName}
                  tenantId={tenantId}
                />
              </Fragment>
            ))
          }
          </ListGroup>
        </div>
      </center>
    </div>
    </CustomLoader>
  );
};

export default ManageShiftGroups;
