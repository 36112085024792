import MainHeader from '../../navigation/MainHeader';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './TenantDetails.module.css';
import { useEffect, useState } from 'react';
import CustomLoader from '../../ui-common/CustomLoader';
import { firebaseObserver } from '../../../firebaseConfig';
import { TenantStatusList } from '../../../models/status';
import { useAuthContext } from "../../context/useAuthContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { TenantPlanEnum, TenantPlanList } from '../../../models/plan';
import { TenantPlanConfigFreeTrial, TenantPlanConfigStandard, TenantPlanConfigPremium } from '../../../models/tenant-plan-config';
import { Timestamp } from 'firebase/firestore/lite';
import { LockFill, UnlockFill } from "react-bootstrap-icons";
import { getDocument } from '../../database/platform/get';
import { updateDocument } from '../../database/platform/update';


const TenantDetails = () => {

  const params = useParams();
  const tenantId = params.tenantId;
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);

  // Input listeners
  const [tenantName, setTenantName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [plan, setPlan] = useState('');
  const [planEndDate, setPlanEndDate] = useState('');
  const [status, setStatus] = useState('');
  const [statusReason, setStatusReason] = useState('');
  const [modifiedBy, setModifiedBy] = useState('');
  const [dateModified, setDateModified] = useState('');
  const [tenantConfig, setTenantConfig] = useState('');

  // Page Effects
  const [disablePlanChange, setDisablePlanChange] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const statusList = [...TenantStatusList];
  const index = statusList.indexOf("*");
  if (index > -1) {
    statusList.splice(index, 1);
  }

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role === "SUPERADMIN") {
          setDisplayName(userAuthContext.displayName);
          setDisablePlanChange(false);
        } else if (userAuthContext.role === "ADMIN"
          && userAuthContext.tenantId === params.tenantId) {
            setDisplayName(userAuthContext.displayName);
            setDisablePlanChange(true);
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
      console.log(userAuthContext);
    });
    if (user) {
      user.getIdTokenResult().then(function(data) {
        if (data.claims?.role === "SUPERADMIN") {
          setDisablePlanChange(false);
        } else if (data.claims?.role === "ADMIN") {
          setDisablePlanChange(true);
        }
      });
    }
    searchTenant(params.tenantId);
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, params.tenantId]);

  // DB call functions
  async function searchTenant(tenantId) {
    const tenantResult = await getDocument("tenants", tenantId);
    const tenantSettingsResult = await getDocument("settings", tenantId);
    populateFields(tenantResult.data(), tenantSettingsResult.data());
  }
  function populateFields(tenantDocument, tenantSettingsDocument) {
    setTenantName(tenantDocument.tenantName);
    setOwnerName(tenantDocument.ownerName);
    setOwnerEmail(tenantDocument.ownerEmail);
    setContactNumber(tenantDocument.contactNumber);
    setPlan(tenantDocument.plan);
    const thirtyDays = new Date(Timestamp.now().toDate().setDate(Timestamp.now().toDate().getDate() + 30));
    setPlanEndDate(tenantDocument.planEndDate ? tenantDocument.planEndDate.toDate() : thirtyDays);
    setStatus(tenantDocument.status);
    setStatusReason(tenantDocument.statusReason);
    setModifiedBy(tenantDocument.modifiedBy ?? displayName);
    setDateModified(tenantDocument.dateModified.toDate() ?? Timestamp.now());
    setSubmitSuccess(false);
  }

  function tenantNameListener(event) {
    setTenantName(event.target.value);
  }
  function ownerNameListener(event) {
    setOwnerName(event.target.value);
  }
  function ownerEmailListener(event) {
    setOwnerEmail(event.target.value);
  }
  function contactNumberListener(event) {
    setContactNumber(event.target.value);
  }
  function planListener(plan) {
    setPlan(plan);
    if (plan === TenantPlanEnum.FREE_TRIAL) {
      setTenantConfig(TenantPlanConfigFreeTrial);
    }
    if (plan === TenantPlanEnum.STANDARD) {
      setTenantConfig(TenantPlanConfigStandard);
    }
    if (plan === TenantPlanEnum.PREMIUM) {
      setTenantConfig(TenantPlanConfigPremium);
    }
  }
  function planEndDateListener(event) {
    setPlanEndDate(event.target.value);
  }
  function statusListener(status) {
    setStatus(status);
  }
  function statusReasonListener(event) {
    setStatusReason(event.target.value);
  }

  // triggering search when button is pressed
  async function editTenant(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }

    const timestamp = Timestamp.now().toDate();
    timestamp.setDate(timestamp.getDate() + 30);
    const data = {
      tenantId: tenantId,
      tenantName: tenantName,
      ownerName: ownerName,
      ownerEmail: ownerEmail,
      contactNumber: contactNumber,
      plan: plan,
      planEndDate: planEndDate,
      status: status,
      statusReason: statusReason,
    };
    // Enable loader when request is being sent.
    setLoaderState(true);

    const createTenantResult = await updateDocument("tenants", tenantId, data, displayName);
    let configTenantResult = true;
    if (tenantConfig) {
      configTenantResult = await updateDocument("settings", tenantId, tenantConfig, displayName);
    }

    if (!createTenantResult || !configTenantResult) {
      setLoaderState(false);
      return;
    }
    setSubmitSuccess(true);
    setLoaderState(false);
    setEnableEdit(!enableEdit);
    setValidated(false);
  }

  function toggleEdit() {
    if (enableEdit) {
      alert('You will lose the changes you made. Please click the "Modify Tenant and Save" Button next time if you want to save your changes');
      searchTenant(params.tenantId);
    }
    setEnableEdit(!enableEdit);
    setValidated(false);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center>
        <h3 style={{marginTop:15}}>Tenant &nbsp;
        { enableEdit
          ? <UnlockFill onClick={toggleEdit}/>
          : <LockFill onClick={toggleEdit}/>
        }</h3>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Tenant Successfully Created.</h3>
                Tenant Id: {tenantId} <br></br>
                Tenant Name: {tenantName} <br></br>
                Owner Name: {ownerName} <br></br>
                Owner Email: {ownerEmail} <br></br>
                Contact Number: {contactNumber} <br></br>
                Plan: {plan} <br></br>
                <br></br><br></br>
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>View Tenant Details</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/searchtenant')}}>Search Another Tenant</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/')}}>Exit</Button>
              </div>
             </center>
          : <div className={classes.mainInputContainer}>
            { enableEdit
              ? <Form noValidate validated={validated} onSubmit={editTenant}>
                  <Form.Group className="mb-3" controlId="inputItem">
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="tenantId">Tenant Id</InputGroup.Text>
                      <Form.Control
                        required
                        pattern="^(?!.*\s)[a-zA-Z0-9]+$"
                        type="text"
                        placeholder="Unique Tenant Identifier"
                        value={tenantId}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Must not be blank, no spaces and no special characters.
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="tenantName">Tenant Name</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Tenant Name/Organization Name"
                        value={tenantName}
                        onChange={tenantNameListener}
                      />
                      <Form.Control.Feedback type="invalid">
                        Tenant Name should not be blank.
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="ownerName">Owner Name</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Organization Owner Full Name"
                        value={ownerName}
                        onChange={ownerNameListener}
                      />
                      <Form.Control.Feedback type="invalid">
                        Owner Name should not be blank.
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="ownerEmail">Owner Email</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Email"
                        value={ownerEmail}
                        onChange={ownerEmailListener}
                      />
                      <Form.Control.Feedback type="invalid">
                        Email should not be blank.
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="contactNumber">Contact Number</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Contact Number"
                        value={contactNumber}
                        onChange={contactNumberListener}
                      />
                      <Form.Control.Feedback type="invalid">
                        Contact Numer should not be blank.
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="plan">Plan</InputGroup.Text>
                      <DropdownButton id="plan-dropdown-button" title={plan}>
                      {
                        TenantPlanList.map((plan) => (
                          <Dropdown.Item
                            key={plan}
                            disabled={disablePlanChange}
                            onClick={() => {planListener(plan)}}
                          >
                            {plan}

                          </Dropdown.Item>
                        ))
                      }
                      </DropdownButton>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="planEndDate">Plan End Date</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={planEndDate}
                        onChange={planEndDateListener}
                        disabled={disablePlanChange}
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="status">Status</InputGroup.Text>
                      <DropdownButton id="status-dropdown-button" title={status}>
                      {
                        statusList.map((status) => (
                          <Dropdown.Item
                            key={status}
                            onClick={() => {statusListener(status)}}
                          >
                            {status}
                          </Dropdown.Item>
                        ))
                      }
                      </DropdownButton>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="statusReason">Status Reason</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={statusReason}
                        onChange={statusReasonListener}
                      />
                      <Form.Control.Feedback type="invalid">
                        Status Reason should not be blank.
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={modifiedBy}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="dateModified">Date Modified</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={dateModified}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                  <center>
                    <Button variant="primary" onClick={() => {navigate(`/usermanagement/${tenantId}`);}}>Manage Users</Button>
                    &nbsp;
                    <Button type="submit">Modify Tenant and Save</Button>
                  </center>
                </Form>
              : <Form noValidate validated={validated}>
                  <Form.Group className="mb-3" controlId="inputItem">
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="tenantId">Tenant Id</InputGroup.Text>
                      <Form.Control
                        required
                        pattern="^(?!.*\s)[a-zA-Z0-9]+$"
                        type="text"
                        placeholder="Unique Tenant Identifier"
                        value={tenantId}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="tenantName">Tenant Name</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Tenant Name/Organization Name"
                        value={tenantName}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="ownerName">Owner Name</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Organization Owner Full Name"
                        value={ownerName}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="ownerEmail">Owner Email</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Email"
                        value={ownerEmail}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="contactNumber">Contact Number</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Contact Number"
                        value={contactNumber}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="plan">Plan</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={plan}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="planEndDate">Plan End Date</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={planEndDate}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Plan End Date should not be blank.
                      </Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="status">Status</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={status}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="statusReason">Status Reason</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={statusReason}
                        disabled
                      />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={modifiedBy}
                        disabled
                      />
                      </InputGroup>
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="dateModified">Date Modified</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={dateModified}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                  <center>
                    <Button variant="primary" onClick={() => {navigate(`/usermanagement/${tenantId}`);}}>Manage Users</Button>
                    &nbsp;
                    <Button variant="primary" onClick={() => {navigate(`/holidaymanagement/${tenantId}`);}}>Manage Holidays</Button>
                    &nbsp;
                    <Button variant="primary" onClick={() => {navigate(`/manageshiftgroup/${tenantId}`);}}>Manage Shift Groups</Button>
                    &nbsp;
                    <Button variant="primary" onClick={() => {navigate('/');}}>Exit</Button>
                  </center>
                </Form>
              }
            </div>
        }
      </center>
    </div>
    </CustomLoader>
  );
};

export default TenantDetails;
