import MainHeader from '../../../components/navigation/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from './CreateTenant.module.css';
import { useEffect, useState } from 'react';
import CustomLoader from '../../../components/ui-common/CustomLoader';
import { firebaseObserver } from '../../../firebaseConfig';
import { insertDocument } from '../../../components/database/platform/insert';
import { TenantStatusEnum } from '../../../models/status';
import { useAuthContext } from "../../../components/context/useAuthContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { TenantPlanEnum, TenantPlanList } from '../../../models/plan';
import { TenantPlanConfigFreeTrial, TenantPlanConfigStandard, TenantPlanConfigPremium } from '../../../models/tenant-plan-config';
import { Timestamp } from 'firebase/firestore/lite';
import { createSubcollection } from '../../../components/database/tenant/createSubcollection';
import { searchDocuments } from '../../../components/database/platform/search';
import { insertSubcollectionDocument } from '../../../components/database/tenant/insert';
import { DefaultShiftGroup } from '../../../models/default-shift-group';


const CreateTenant = () => {

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);

  // Input listeners
  const [tenantId, setTenantId] = useState('');
  const [tenantName, setTenantName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [plan, setPlan] = useState(TenantPlanEnum.FREE_TRIAL);
  const [tenantConfig, setTenantConfig] = useState(TenantPlanConfigFreeTrial);

  // Page Effects
  const [loaderState, setLoaderState] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role !== "SUPERADMIN") {
          alert('Unauthorized access.');
          navigate('/');
        }
        setDisplayName(userAuthContext.displayName);
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
      console.log(userAuthContext);
    });
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
  }, [navigate, user]);

  // Input Listeners
  function tenantIdListener(event) {
    setTenantId(event.target.value);
  }
  function tenantNameListener(event) {
    setTenantName(event.target.value);
  }
  function ownerNameListener(event) {
    setOwnerName(event.target.value);
  }
  function ownerEmailListener(event) {
    setOwnerEmail(event.target.value);
  }
  function contactNumberListener(event) {
    setContactNumber(event.target.value);
  }
  function planListener(plan) {
    setPlan(plan);
    if (plan === TenantPlanEnum.FREE_TRIAL) {
      setTenantConfig(TenantPlanConfigFreeTrial);
    }
    if (plan === TenantPlanEnum.STANDARD) {
      setTenantConfig(TenantPlanConfigStandard);
    }
    if (plan === TenantPlanEnum.PREMIUM) {
      setTenantConfig(TenantPlanConfigPremium);
    }
  }

  // triggering search when button is pressed
  async function createTenant(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }

    const timestamp = Timestamp.now().toDate();
    timestamp.setDate(timestamp.getDate() + 30);
    const data = {
      tenantId: tenantId,
      tenantName: tenantName,
      ownerName: ownerName,
      ownerEmail: ownerEmail,
      contactNumber: contactNumber,
      plan: plan,
      planEndDate: Timestamp.fromDate(timestamp),
      status: TenantStatusEnum.ACTIVE,
      statusReason: "New Tenant",
    };
    // Enable loader when request is being sent.
    setLoaderState(true);

    const currentYear = new Date().getFullYear();
    const holidaysThisYear = await searchDocuments("defaultHolidays", [{key: "year", inputValue: currentYear}], 100, "", "", true);
    if (!holidaysThisYear) {
      alert ("No holidays configured in platform for the current year.");
      console.log("No holidays configured in platform for the current year.");
      setLoaderState(false);
      return;
    };
    const createHolidaysResult = await insertDocument("holidays", "tenantId", {tenantId: tenantId}, displayName, tenantId);
    await createSubcollection("holidays", tenantId, "holidays");
    for (const holiday of holidaysThisYear.docs) {
      await insertSubcollectionDocument("holidays", tenantId, "holidays", holiday, displayName, holiday.docId);
    }
    const createShiftGroupsResult = await insertDocument("shiftGroups", "tenantId", {tenantId: tenantId}, displayName, tenantId);
    await createSubcollection("shiftGroups", tenantId, "shiftGroups");
    await insertSubcollectionDocument("shiftGroups", tenantId, "shiftGroups", DefaultShiftGroup, displayName);
    const createTenantResult = await insertDocument("tenants", "tenantId", data, displayName, tenantId);
    tenantConfig.tenantId = tenantId;
    const configTenantResult = await insertDocument("settings", "tenantId", tenantConfig, displayName, tenantId);
    const createUsersResult = await insertDocument("users", "tenantId", {tenantId: tenantId}, displayName, tenantId);
    await createSubcollection("users", tenantId, "users");
    const createBroadcastsResult = await insertDocument("broadcasts", "tenantId", {tenantId: tenantId}, displayName, tenantId);
    await createSubcollection("broadcasts", tenantId, "broadcasts");
    const createTimeEntriesResult = await insertDocument("timeEntries", "tenantId", {tenantId: tenantId}, displayName, tenantId);
    await createSubcollection("timeEntries", tenantId, "timeEntries");

    if (!createHolidaysResult
        || !createShiftGroupsResult
        || !createTenantResult
        || !configTenantResult
        || !createUsersResult
        || !createBroadcastsResult
        || !createTimeEntriesResult) {
      setLoaderState(false);
      return;
    }
    setSubmitSuccess(true);
    setLoaderState(false);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center>
        <h3 style={{marginTop:15}}>Create Tenant</h3>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Tenant Successfully Created.</h3>
                Tenant Id: {tenantId} <br></br>
                Tenant Name: {tenantName} <br></br>
                Owner Name: {ownerName} <br></br>
                Owner Email: {ownerEmail} <br></br>
                Contact Number: {contactNumber} <br></br>
                Plan: {plan} <br></br>
                <br></br><br></br>
                <Button variant="primary" onClick={() => {navigate(`/tenantdetails/${tenantId}`)}}>View Tenant Details</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>Create Another Tenant</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/')}}>Exit</Button>
              </div>
             </center>
          : <div className={classes.mainInputContainer}>
            <Form noValidate validated={validated} onSubmit={createTenant}>
              <Form.Group className="mb-3" controlId="inputItem">
                <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="tenantId">Tenant Id</InputGroup.Text>
                  <Form.Control
                    required
                    pattern="^(?!.*\s)[a-zA-Z0-9]+$"
                    type="text"
                    placeholder="Unique Tenant Identifier"
                    value={tenantId}
                    onChange={tenantIdListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Must not be blank, no spaces and no special characters.
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="tenantName">Tenant Name</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Tenant Name/Organization Name"
                    value={tenantName}
                    onChange={tenantNameListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Tenant Name should not be blank.
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="ownerName">Owner Name</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Organization Owner Full Name"
                    value={ownerName}
                    onChange={ownerNameListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Owner Name should not be blank.
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="ownerEmail">Owner Email</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Email"
                    value={ownerEmail}
                    onChange={ownerEmailListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Email should not be blank.
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="contactNumber">Contact Number</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Contact Number"
                    value={contactNumber}
                    onChange={contactNumberListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Contact Numer should not be blank.
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="plan">Plan</InputGroup.Text>
                  <DropdownButton id="plan-dropdown-button" title={plan}>
                  {
                    TenantPlanList.map((plan) => (
                      <Dropdown.Item
                        key={plan}
                        onClick={() => {planListener(plan)}}
                      >
                        {plan}
                      </Dropdown.Item>
                    ))
                  }
                  </DropdownButton>
                </InputGroup>
              </Form.Group>
              <center><Button type="submit">Create Tenant</Button></center>
            </Form>
            </div>
        }
      </center>
    </div>
    </CustomLoader>
  );
};

export default CreateTenant;
