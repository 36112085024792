import * as React from "react";
import MainHeader from "../components/navigation/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./Home.module.css";
import { useEffect, useState } from "react";
import CustomLoader from "../components/ui-common/CustomLoader";
import { firebaseObserver } from "../firebaseConfig";
import Footer from "../components/navigation/Footer";
import { useAuthContext } from "../components/context/useAuthContext";
import { listSubcollectionDocuments } from "../components/database/tenant/get";


const Home = () => {

  // User Auth Context
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);

  const [messageList, setMessageList] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [tenantId, setTenantId] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (!userAuthContext) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(userAuthContext.displayName);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        setTenantId(data.claims?.tenantId);
      });
    }
    if (tenantId) {
      listAllBroadcasts(tenantId);
    }
    console.log(tenantId);
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
  }, [navigate, displayName, tenantId, user]);

  async function listAllBroadcasts(tenantId) {
    // Enable loader when request is being sent.
    setLoaderState(true);

    const messages = await listSubcollectionDocuments("broadcasts", tenantId, "broadcasts", "dateEntry", "dateEntry", "desc");
    setMessageList(messages);

    setLoaderState(false);
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>Message updates</h4>
        <ul className={classes.viewBox}>
        {
          messageList.map((val) => (
            <li key={val.dateEntry} style={{marginBottom:20}}>
              <b>{val.dateEntry.toDate().toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric"
              })}:</b>
              <br></br>
              {val.message}
            </li>
          ))
        }
        </ul>
        </center>
        <br></br>
        <br></br>
        <Footer />
      </div>
    </CustomLoader>
  );
}

export default Home;
