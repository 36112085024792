import MainHeader from '../../../components/navigation/MainHeader';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './ManageLeavesTenant.module.css';
import { useEffect, useState } from 'react';
import CustomLoader from '../../../components/ui-common/CustomLoader';
import { firebaseObserver } from '../../../firebaseConfig';
import { useAuthContext } from "../../../components/context/useAuthContext";
import ListGroup from 'react-bootstrap/ListGroup';
import { LeaveApprovalEnum, LeaveApprovalList } from '../../../models/leaves';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import { searchSubcollectionDocuments } from '../../../components/database/tenant/searchSubcollection';
import LeavesListActionApproveModal from '../../../components/functional/leaves/LeavesListApproveActionModal';
import FullTextSearchFn from '../../../components/functional/FullTextSearchFn';



const ManageLeavesTenant = () => {

  const params = useParams();
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [loaderState, setLoaderState] = useState(false);
  const tenantId = params.tenantId;

  // Query pagination
  const [searchInput, setSearchInput] = useState('');

  // Leaves List
  const [origLeaves, setOrigLeaves] = useState([]);
  const [leaves, setLeaves] = useState([]);
  // Leave Status List
  const leaveStatusList = LeaveApprovalList;
  const [selectedLeaveStatus, setSelectedLeaveStatus] = useState(LeaveApprovalEnum.PENDING_APPROVAL);
  const [selectedSortDirection, setSelectedSortDirection] = useState("Newest");
  const [sortDirection, setSortDirection] = useState("desc");

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role === "SUPERADMIN") {
          setDisplayName(userAuthContext.displayName);
        } else if (userAuthContext.role === "ADMIN"
          && userAuthContext.tenantId === tenantId) {
            setDisplayName(userAuthContext.displayName);
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
      // console.log(userAuthContext);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        if (data.claims?.role !== "SUPERADMIN" && data.claims?.role !== "ADMIN") {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    generateList();
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, params.tenantId]);

  async function generateList() {
    console.log('USE EFFECT EXECUTED');

    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    let leavesResult;
    try {
      leavesResult = await searchSubcollectionDocuments("leaves", tenantId, "leaves", queryFilters, 1000, false, "leaveDate", sortDirection);
      if (!leavesResult) {
        setLoaderState(false);
        return;
      }
    } catch(error) {
      console.log(`Error in DB query: ${error}`);
      setLoaderState(false);
      return;
    }
    setOrigLeaves(leavesResult.docs);
    setLeaves(leavesResult.docs);
    setLoaderState(false);
  }

  function selectedLeaveStatusListener(status) {
    setSelectedLeaveStatus(status);
  }
  function selectedSortListener(sortDirection) {
    setSelectedSortDirection(sortDirection);
    setSortDirection(sortDirection === "Oldest" ? "asc" : "desc");
  }
  function searchInputListener(event) {
    setSearchInput(event.target.value);
    setLeaves(FullTextSearchFn(origLeaves, event.target.value, "displayName", true));
  }

  function parseQueryFilter() {
    const queryFilters = [];
    if (selectedLeaveStatus) {
      queryFilters.push({key:"status", inputValue:selectedLeaveStatus});
    }
    return queryFilters;
  }

  function reloadComponent(reloadSwitch) {
    if (reloadSwitch === true) {
      generateList();
    }
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center>
        <h3 style={{marginTop:15}}>Manage Leave Approvals</h3>
        <div className={classes.mainInputContainer}>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
            <Button variant="primary" onClick={generateList}>
              GENERATE LIST
            </Button>
            <br></br>
            <br></br>
            <InputGroup size="sm" className="justify-content-center">
            <InputGroup.Text id="element">Leave Status</InputGroup.Text>
            <DropdownButton id="leave-status-dropdown-button" title={selectedLeaveStatus}>
            {
              leaveStatusList.map((status) => (
                <Dropdown.Item key={status} onClick={() => {selectedLeaveStatusListener(status)}}>{status}</Dropdown.Item>
              ))
            }
            </DropdownButton>
            &nbsp;&nbsp;&nbsp;
            <InputGroup.Text id="element">Sort Direction</InputGroup.Text>
            <DropdownButton id="sort-dropdown-button" title={selectedSortDirection}>
              <Dropdown.Item key="asc" onClick={() => {selectedSortListener("Oldest")}}>Oldest</Dropdown.Item>
              <Dropdown.Item key="desc" onClick={() => {selectedSortListener("Newest")}}>Newest</Dropdown.Item>
            </DropdownButton>
            </InputGroup>
            <br></br>
            <Form.Control
              type="searchDisplayName" placeholder="Type Display Name Here To Search" value={searchInput}
              onChange={searchInputListener}
            />
          </Form.Group>
          </Form>
          <ListGroup style={{overflow:"scroll", maxHeight:"300px"}}>
          {
            leaves.map((leave) => (
              <LeavesListActionApproveModal
                key={`${leave.leaveDate}+${leave.displayName}`}
                leave={leave}
                displayName={displayName}
                tenantId={tenantId}
                onSuccess={reloadComponent}
              />
            ))
          }
          </ListGroup>
        </div>
      </center>
    </div>
    </CustomLoader>
  );
};

export default ManageLeavesTenant;
