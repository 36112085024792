import { db } from '../../../firebaseConfig';
import { collection, doc, deleteDoc } from 'firebase/firestore/lite';


export const deleteSubcollectionDocument = async (collectionName, tenantId, subCollectionName, docId) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);


  try {
    await deleteDoc(doc(subcollectionRef, docId));
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return false;
  }

  return true;
};
