import MainHeader from "../../components/navigation/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./UserProfile.module.css";
import { Fragment, useEffect, useState } from "react";
import CustomLoader from "../../components/ui-common/CustomLoader";
import { auth, firebaseObserver } from "../../firebaseConfig";
// import { db, auth, firebaseObserver } from "../../firebaseConfig";
// import { getDoc, doc } from 'firebase/firestore/lite';
import { sendPasswordResetEmail } from "firebase/auth"
import Button from "react-bootstrap/Button";
import { useAuthContext } from "../../components/context/useAuthContext";
// import SetTimeInPinModal from "../../components/SetTimeInPinModal";
import { getSubcollectionDocument } from "../../components/database/tenant/get";


const UserProfile = () => {
  // User Auth Context
  const { user } = useAuthContext();

  // User list state
  const [userPofile, setUserProfile] = useState();
  // Tenant Id
  const [tenantId, setTenantId] = useState('');
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);
  // // Input Type toggle
  // const [inputTypeToggle, setInputTypeToggle] = useState(false);

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (!userAuthContext) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(userAuthContext.displayName);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        setTenantId(data.claims?.tenantId);
      });
    }
    if (displayName && tenantId) {
      getUser(displayName);
    }
    return () => { firebaseObserver.unsubscribe('userAuthContext'); }
  },[displayName, tenantId]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  async function getUser(displayName) {
    // Enable loader when request is being sent.
    setLoaderState(true);
    console.log(tenantId);
    console.log(displayName);
    const userRecord = await getSubcollectionDocument("users", tenantId, "users", "displayName", displayName);
    const user = userRecord.docs[0].data()
    const compensationRecords = await getSubcollectionDocument("compensation", tenantId, "compensation", "displayName", displayName) ?? [];
    let compensationList =[];
    for (const compensation of compensationRecords.docs) {
      compensationList.push(compensation.data());
    }
    user.compensation = compensationList;
    setUserProfile(user);
    // setLeaveBalanceVacation(await getLeaveBalance(displayName, user.data(), 'Vacation Leave', 'vacationLeaves'));
    // setLeaveBalanceSick(await getLeaveBalance(displayName, user.data(), 'Sick Leave', 'sickLeaves'));
    setLoaderState(false);
  }

  function resetPassword(email) {
    setLoaderState(true);
    return sendPasswordResetEmail(auth, email).then((a) => {
      setLoaderState(false);
      alert(`Password reset email sent to ${email}.`);
    });
  }

  // function toggleMaskPinHandler() {
  //   setInputTypeToggle(!inputTypeToggle);
  // }

  // function getSaveConfirmation(isSuccess) {
  //   if (isSuccess){
  //     getUser(displayName);
  //   }
  // }

  // async function getLeaveBalance(displayName, userDetails, leaveType, leaveTypeKey) {
  //   // Get year of leave date to determine balance remaining.
  //   let leaveBalance = 0;
  //   const year = DateTime.local().year.toString();
  //     let leaveCredits = 0;
  //     // Check if user has leaves configured
  //     if (userDetails[leaveTypeKey]) {
  //       const leaveExistsCheck = userDetails[leaveTypeKey].find((leaveItem) => {
  //         if (leaveItem.year === year) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       });
  //       if (!leaveExistsCheck) {
  //         alert(`No ${leaveType} credits configured for the year ${year}, please ask HR for assistance.`);
  //         return 0;
  //       }
  //       userDetails[leaveTypeKey].forEach((leaveItem) => {
  //         if (leaveItem.year === year) {
  //           leaveCredits = leaveItem.credits;
  //         }
  //       });
  //       const leavesRef = doc(db, "leaves", displayName);
  //       const leaveDocSnap = await getDoc(leavesRef);
  //       // Iterate through existing leaves in leaves collection
  //       if (leaveDocSnap.exists()) {
  //         const leave = leaveDocSnap.data();
  //         // Iterate through each datfiled based on year
  //         for (const key of Object.keys(leave)) {
  //           // Iterate through leavecount of each dateFiled
  //           for (const subKey of Object.keys(leave[key])) {
  //             // Check if leave status is approved or pending
  //             //   include in total if they are
  //             if ((leave[key][subKey].status === 'pending' ||
  //                 leave[key][subKey].status === 'approved') &&
  //                 leave[key][subKey].leaveType === leaveType ) {
  //                   leaveBalance += leave[key][subKey].count;
  //             }
  //           }
  //         }
  //       }
  //       const remainingLeaves = leaveCredits - leaveBalance;
  //       return remainingLeaves;
  //     } else {
  //       alert(`No ${leaveType} credits configured for the year ${year}, please ask HR for assistance.`);
  //       return 0;
  //     }
  // }

  return (
    <CustomLoader
      isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>User Profile</h4>
        <ul className={classes.viewBox}>
        { userPofile ?
          <label>
            <b>Display Name:</b> {userPofile.displayName} <br></br>
            <b>Email:</b> {userPofile.email} <br></br>
            <b>First Name:</b> {userPofile.firstName} <br></br>
            <b>Last Name:</b> {userPofile.lastName} <br></br>
            <b>Birthday:</b> {userPofile.birthday} <br></br>
            <b>Start Date:</b> {userPofile.startDate} <br></br>
            <b>Shift Group:</b> {userPofile.shiftGroup} <br></br>
            <b>Leaves:</b> <br></br>
            <Fragment>
              { userPofile.compensation
                ? <Fragment>
                  { userPofile.compensation.length > 0
                    ? <Fragment>
                      { userPofile.compensation.map((compensationYear) =>
                        (
                          <Fragment>
                            <b>Year</b>: {compensationYear.year} &nbsp;
                            <b>Leave Credits</b>: {compensationYear.leaveCredits}
                            <br></br>
                          </Fragment>
                        ))
                      }
                      </Fragment>
                    : <b>No Leaves Configured. Please contact HR</b>
                  }
                  </Fragment>
                : <b>No Leaves Configured. Please contact HR</b>
              }
            </Fragment>
          </label>
        : null }
        <br></br>
        <br></br>
        <Button variant='primary' onClick={() => resetPassword(userPofile.email)}>
          Reset My Account Password
        </Button>
        </ul>
        </center>
      </div>
    </CustomLoader>
  );
}

export default UserProfile;
