import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import { functions } from "../../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { UserRolesEnum, UserRolesList } from "../../models/user-roles";
import { useNavigate } from "react-router-dom";


const CreateUserModal = (props) => {

  const tenantId = props.tenantId;
  const [displayName, setDisplayName] = useState('');
  const [role, setRole] = useState(UserRolesEnum.USER);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [shiftGroup, setShiftGroup] = useState(props.shiftGroups[0].shiftGroup);
  const [dateStart, setDateStart] = useState('');
  const [leaveCredits, setLeaveCredits] = useState(0);
  // const [dailySalary, setDailySalary] = useState(0);
  // const [monthlySalary, setMonthlySalary] = useState(0);

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [userRoleList, setUserRoleList] = useState(UserRolesList);
  const shiftGroups = props.shiftGroups;

  const navigate = useNavigate();
  useEffect(() => {
    if (!props.isSuperAdmin) {
      setUserRoleList(['ADMIN','USER']);
    } else {
      setUserRoleList(UserRolesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, props.tenantId, props.reload, props.isSuperAdmin]);

  const handleClose = () => {
    resetInputs();
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }

  function displayNameListener(event) {
    setDisplayName(event.target.value);
  }
  function roleListener(value) {
    setRole(value);
  }
  function emailListener(event) {
    setEmail(event.target.value);
  }
  function phoneNumberListener(event) {
    setPhoneNumber(event.target.value);
  }
  function passwordListener(event) {
    setPassword(event.target.value);
  }
  function firstNameListener(event) {
    setFirstName(event.target.value);
  }
  function lastNameListener(event) {
    setLastName(event.target.value);
  }
  function shiftGroupListener(value) {
    setShiftGroup(value);
    if (value === "Create New Shift Group") {
      navigate(`/manageshiftgroup/${tenantId}`);
    }
  }
  function dateStartListener(event) {
    setDateStart(event.target.value);
  }
  function leaveCreditsListener(event) {
    setLeaveCredits(event.target.value);
  }
  // function dailySalaryListener(event) {
  //   setDailySalary(event.target.value);
  // }
  // function monthlySalaryListener(event) {
  //   setMonthlySalary(event.target.value);
  // }

  async function clickHandler(event)  {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    // Enable loader when request is being sent.
    setLoaderState(true);
    const createUser = httpsCallable(functions, 'createUser');
    await createUser({
      displayName: displayName,
      tenantId: tenantId,
      role: role,
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      shiftGroup: shiftGroup,
      dateStart: dateStart,
      compensation: {
        displayName: displayName,
        year: new Date().getFullYear().toString(),
        leaveCredits: parseInt(leaveCredits),
        // dailySalary: parseFloat(dailySalary),
        // monthlySalary: parseFloat(monthlySalary),
      }
    })
    .then((response) => {
      setLoaderState(false);
      if (response.data?.displayName) {
        alert(`${response.data.displayName} has been created successfully.`);
        props.onConfirmation(true);
        resetInputs();
        setShow(false);
      } else {
        alert(response.data.errorInfo.message);
      }
    })
    .catch((error) => {
      setLoaderState(false);
      alert(error);
    });
    setLoaderState(false);
  }

  function resetInputs() {
    setValidated(false);
    setDisplayName('');
    setRole(UserRolesEnum.USER);
    setEmail('');
    setPhoneNumber('');
    setPassword('');
    setFirstName('');
    setLastName('');
    setShiftGroup('');
    setDateStart('');
    setLeaveCredits(0);
    // setDailySalary(0);
    // setMonthlySalary(0);
  }

  return (
    <React.Fragment>
      <Button
        variant="primary"
        onClick={handleShow}>
        Create Users
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form noValidate validated={validated} onSubmit={clickHandler}>
        <Form.Group className="mb-3" controlId="inputItem">

          <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="displayName">Display Name:</InputGroup.Text>
            <Form.Control
              required
              pattern="^(?!.*\s)[a-zA-Z0-9]+$"
              type="text"
              placeholder="Unique username use this format FirstnameLastname e.g. JuanDelaCruz"
              value={displayName}
              onChange={displayNameListener}
            />
            <Form.Control.Feedback type="invalid">
              Must not be blank, no spaces and no special characters.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="role">User Role:</InputGroup.Text>
            <Dropdown
              id="dropdown-button"
              as={Form.Control}
              value={role}
              >
            <Dropdown.Toggle variant="primary">
              {role}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                userRoleList.map((val) => (
                  <Dropdown.Item
                    key={val}
                    onClick={() => {roleListener(val)}}>
                      {val}
                  </Dropdown.Item>
                ))
              }
            </Dropdown.Menu>
          </Dropdown>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="email">Email:</InputGroup.Text>
            <Form.Control
              required
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              type="text"
              placeholder="user_email@another.com"
              value={email}
              onChange={emailListener}
            />
            <Form.Control.Feedback type="invalid">
              Email must not be blank and must be in proper email format.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="phoneNumber">Phone Number:</InputGroup.Text>
            <Form.Control
              type="text"
              pattern="\+63\d{10}"
              placeholder="+639xx xxx xxxx"
              value={phoneNumber}
              onChange={phoneNumberListener}
            />
            <Form.Control.Feedback type="invalid">
              Cellphone number must follow international format +63xxxxxxxxxx.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="firstName">First Name:</InputGroup.Text>
            <Form.Control
              required
              type="text"
              placeholder="Juan"
              value={firstName}
              onChange={firstNameListener}
            />
            <InputGroup.Text id="lastName">Last Name:</InputGroup.Text>
            <Form.Control
              required
              type="text"
              placeholder="Dela Cruz"
              value={lastName}
              onChange={lastNameListener}
            />
            <Form.Control.Feedback type="invalid">
              First name and Last name must not be blank.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="shiftGroup">Shift Group:</InputGroup.Text>
              <Dropdown
                  required
                  id="dropdown-button"
                  as={Form.Control}
                  value={shiftGroup}
                  >
                <Dropdown.Toggle variant="primary">
                  {shiftGroup}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    shiftGroups.map((shiftGroup) => (
                      <Dropdown.Item
                        key={shiftGroup.shiftGroup}
                        onClick={() => {shiftGroupListener(shiftGroup.shiftGroup)}}>
                          {shiftGroup.shiftGroup}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="dateStart">Date Start:</InputGroup.Text>
            <Form.Control
              type="date"
              value={dateStart}
              onChange={dateStartListener}
            />
            <Form.Control.Feedback type="invalid">
              Date Start must not be blank.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="password">Temporary Password:</InputGroup.Text>
            <Form.Control
              type="text"
              value={password}
              onChange={passwordListener}
            />
            <Form.Control.Feedback type="invalid">
              Temporary password must not be blank.
            </Form.Control.Feedback>
          </InputGroup>
          <br></br>

          <Form.Label><h5>Leave Credits for Year {new Date().getFullYear()}</h5></Form.Label>
          <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="leaveCredits">Leaves:</InputGroup.Text>
            <Form.Control
              required
              type="number"
              value={leaveCredits}
              onChange={leaveCreditsListener}
            />
            <Form.Control.Feedback type="invalid">
              Leave Credits must not be blank.
            </Form.Control.Feedback>
          </InputGroup>

          <br></br>
          <center>
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                center
              />
            :
              <Button
                type="submit"
              >
                Create User
              </Button>
          }
          </center>
        </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CreateUserModal


// <InputGroup className="mb-1" size="sm">
// <InputGroup.Text id="dailySalary">Daily Salary</InputGroup.Text>
// <Form.Control
//   type="number"
//   value={dailySalary}
//   onChange={dailySalaryListener}
// />
// <InputGroup.Text id="monthlySalary">Monthly Salary</InputGroup.Text>
// <Form.Control
//   type="number"
//   value={monthlySalary}
//   onChange={monthlySalaryListener}
// />
// </InputGroup>
