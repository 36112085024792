import { db } from '../../../firebaseConfig';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore/lite';


export const insertSubcollectionDocument = async (collectionName, tenantId, subCollectionName, insertData, contextUser, documentId) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  insertData.modifiedBy = contextUser;
  insertData.dateModified = Timestamp.now();

  try {
    if (documentId) {
      await setDoc(doc(subcollectionRef, documentId), insertData);
    } else {
      await setDoc(doc(subcollectionRef), insertData);
    }
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return false;
  }

  return true;
};
