export const TenantPlanConfigFreeTrial = {
  timeEntryLimitPerUserDay: 2,
  archiveTimeEntryDurationInDays: 30,
  archiveBroadcastsDurationInDays: 30,
  userLimit: 3,
  defaultMapViewCoordinates: { latitude: 12.069080, longitude:123.179474 },
  defaultMapViewZoom: 5,
  mapViewDailyCountLimit: 3,
  enableEmployeeTenureNotifications: false,
}

export const TenantPlanConfigStandard = {
  timeEntryLimitPerUserDay: 4,
  archiveTimeEntryDurationInDays: 90,
  archiveBroadcastsDurationInDays: 90,
  userLimit: 1000,
  defaultMapViewCoordinates: { latitude: 12.069080, longitude:123.179474 },
  defaultMapViewZoom: 5,
  mapViewDailyCountLimit: 300,
  enableEmployeeTenureNotifications: true,
}

export const TenantPlanConfigPremium = {
  timeEntryLimitPerUserDay: 10,
  archiveTimeEntryDurationInDays: 120,
  archiveBroadcastsDurationInDays: 120,
  userLimit: 1000,
  defaultMapViewCoordinates: { latitude: 12.069080, longitude:123.179474 },
  defaultMapViewZoom: 5,
  mapViewDailyCountLimit: 1000,
  enableEmployeeTenureNotifications: true,
}
