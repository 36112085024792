import MainHeader from '../../components/navigation/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from "./ManageBroadcasts.module.css";
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import CustomLoader from "../../components/ui-common/CustomLoader";
import { firebaseObserver } from "../../firebaseConfig";
import { Timestamp } from 'firebase/firestore/lite';
import DeleteBroadcastModal from '../../components/DeleteBroadcastModal';
import EditBroadcastModal from '../../components/EditBroadcastModal';
import { listSubcollectionDocuments } from '../../components/database/tenant/get';
import { useAuthContext } from '../../components/context/useAuthContext';
import { insertSubcollectionDocument } from '../../components/database/tenant/insert';

const ManageBroadcasts = () => {

  // User Auth Context
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  // User list state
  const [messageList, setMessageList] = useState([]);
  // New Message state
  const [newMessage, setNewMessage] = useState('');
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  const [tenantId, setTenantId] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (!userAuthContext) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(userAuthContext.displayName);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        setTenantId(data.claims?.tenantId);
      });
    }
    if (tenantId) {
      listAllBroadcasts(tenantId);
    }
    console.log(tenantId);
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, displayName, tenantId]);

  async function listAllBroadcasts() {
    // Enable loader when request is being sent.
    setMessageList([]);
    setLoaderState(true);
    const messages = await listSubcollectionDocuments("broadcasts", tenantId, "broadcasts", "dateEntry", "dateEntry", "desc");
    setMessageList(messages);
    setLoaderState(false);
  }

  async function createNewMessage() {
    if (!newMessage) {
      alert(`New broadcast message cannot be blank.`);
      return;
    }
    // Enable loader when request is being sent.
    setLoaderState(true);
    await insertSubcollectionDocument("broadcasts", tenantId, "broadcasts", {dateEntry: Timestamp.now(), message: newMessage}, displayName);
    setMessageList([]);
    listAllBroadcasts();
    setLoaderState(false);
    resetInputFields();
  }
  function newMessageListener(event) {
    setNewMessage(event.target.value);
  }
  function resetInputFields() {
    setNewMessage('');
  }
  function getDeleteConfirmation(isSuccess, docId) {
    if (isSuccess){
      const arr = messageList.filter(obj => obj.docId !== docId);
      setMessageList(arr);
      alert("Successfully deleted message.");
    }
  }
  function getEditConfirmation(isSuccess) {
    if (isSuccess){
      listAllBroadcasts();
      alert("Successfully edited message.");
    }
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>Make New Broadcast</h4>
        <div style={{display:'inline-block'}}>
          <textarea style={{marginTop:10, marginBottom:10, height:55, width:300}}
            value={newMessage} id='newMessage' onChange={newMessageListener}
          /> <br></br>
          <Button variant='primary' onClick={createNewMessage}>Create Message</Button>
        </div>
        <h4 style={{marginTop:20}}>Manage Broadcasts</h4>
        <ul className={classes.viewBox}>
        {
          messageList.map((val) => (
            <div key={val.dateEntry}>
              <li style={{display:'inline-block', justifyConte:'center', marginBottom:10, marginTop:10}}>
                <b>{val.dateEntry.toDate().toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                })}</b> <br></br>
                {val.message}
              </li> &nbsp;
              <EditBroadcastModal
                tenantId={tenantId}
                displayName={displayName}
                docId={val.docId}
                message={val.message}
                dateEntry={val.dateEntry}
                onEditConfirmation={getEditConfirmation}
              /> &nbsp;
              <DeleteBroadcastModal
                tenantId={tenantId}
                displayName={displayName}
                docId={val.docId}
                message={val.message}
                dateEntry={val.dateEntry}
                onDeleteConfirmation={getDeleteConfirmation}
              />
            </div>
          ))
        }
        </ul>
        </center>
      </div>
    </CustomLoader>
  );

};

export default ManageBroadcasts;

// <ul id="screenshots" className={classes.tablestyle}>
// {
//   userList.map((val) => (
//     <a key={val.url} href={val.url} target="popup" rel="noreferrer">
//     <li>
//       <img src={val.url} width="50" height="50" alt=""></img>
//       &nbsp; {val.timestamp}
//     </li>
//     </a>
//   ))
// }
// </ul>
