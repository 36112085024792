import { useState, useEffect } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import MainHeader from "../../../components/navigation/MainHeader";
import CustomLoader from '../../../components/ui-common/CustomLoader';
import classes from "./TenantSearch.module.css";
import { firebaseObserver } from '../../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../components/context/useAuthContext";
import { listDocumentsWithPagination, listDocumentsWithPaginationNext, listDocumentsWithPaginationPrev } from "../../../components/database/platform/get";
import FullTextSearchFn from "../../../components/functional/FullTextSearchFn";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { TenantStatusList } from "../../../models/status";


const TenantSearch = () => {

  const dbResultLimit = 50;
  const dbCollectionName = "tenants";
  // Tenant List
  const [tenants, setTenants] = useState([]);
  const [origTenants, setOrigTenants] = useState([]);
  // First Document in query pagination
  const [firstVisible, setFirstVisible] = useState();
  // Last Document in query pagination
  const [lastVisible, setLastVisible] = useState();
  // Previous button switch
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  // Next button switch
  const [disableNextButton, setDisableNextButton] = useState(true);

  // Search Values
  const [searchInputTenantId, setSearchInputTenantId] = useState('');
  const [searchInputTenantName, setSearchInputTenantName] = useState('');
  const [searchInputStatus, setSearchInputStatus] = useState('');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role !== "SUPERADMIN") {
          alert('Unauthorized access.');
          navigate('/');
        }
        setDisplayName(userAuthContext.displayName);
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
      console.log(userAuthContext);
    });
    // if (user) {
    //   user.getIdTokenResult().then(function(data) {
    //     setTenantId(data.claims?.tenantId);
    //   });
    // }
    generateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  function searchInputTenantNameListener(event) {
    setSearchInputTenantId('');
    setSearchInputStatus('');
    setSearchInputTenantName(event.target.value);
    setTenants(FullTextSearchFn(origTenants, event.target.value, "tenantName", true));
  }
  function searchInputTenantIdListener(event) {
    setSearchInputTenantName('');
    setSearchInputStatus('');
    setSearchInputTenantId(event.target.value);
    setTenants(FullTextSearchFn(origTenants, event.target.value, "tenantId", true));
  }
  function searchInputStatusListener(status) {
    setSearchInputTenantId('');
    setSearchInputTenantName('');
    setSearchInputStatus(status);
    setTenants(FullTextSearchFn(origTenants, status, "status", true));
  }

  async function generateList() {
    setLoaderState(true);
    const tenantResult = await listDocumentsWithPagination(dbCollectionName, dbResultLimit, "tenantId", "asc");
    setTenants(tenantResult.docs);
    setOrigTenants(tenantResult.docs);
    setFirstVisible(tenantResult.firstVisibleDocument);
    setLastVisible(tenantResult.lastVisibleDocument);
    if (tenantResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(true);
    setLoaderState(false);
  }

  async function getNext() {
    setLoaderState(true);
    const tenantResult = await listDocumentsWithPaginationNext(dbCollectionName, lastVisible, dbResultLimit, "tenantId", "asc");
    setTenants(tenantResult.docs);
    setOrigTenants(tenantResult.docs);
    setFirstVisible(tenantResult.firstVisibleDocument);
    setLastVisible(tenantResult.lastVisibleDocument);
    if (tenantResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(false);
    setLoaderState(false);
  }

  async function getPrev() {
    setLoaderState(true);
    const tenantResult = await listDocumentsWithPaginationPrev(dbCollectionName, firstVisible, dbResultLimit, "tenantId", "asc");
    setTenants(tenantResult.docs);
    setOrigTenants(tenantResult.docs);
    setFirstVisible(tenantResult.firstVisibleDocument);
    setLastVisible(tenantResult.lastVisibleDocument);
    if (tenantResult.isPrevAvailable) {
      setDisablePrevButton(false);
    } else {
      setDisablePrevButton(true);
    }
    setDisableNextButton(false);
    setLoaderState(false);
  }

  function tenantDetailsLink(tenantId) {
    navigate(`/tenantdetails/${tenantId}`);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Search Tenant</h3></center>
      <div className={classes.mainInputContainer}>
        <center>
          <Form>
            <Form.Group className="mb-3" controlId="inputItem">
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="tenantId">Tenant Id</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={searchInputTenantId}
                  onChange={searchInputTenantIdListener}
                />
              &nbsp;
              <InputGroup.Text id="tenantId">Tenant Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={searchInputTenantName}
                  onChange={searchInputTenantNameListener}
                />
              &nbsp;
              <InputGroup.Text id="status">Status</InputGroup.Text>
                <DropdownButton id="status-dropdown-button" title={searchInputStatus}>
                {
                  TenantStatusList.map((status) => (
                    <Dropdown.Item
                      key={status}
                      onClick={() => {searchInputStatusListener(status)}}
                    >
                      {status}
                    </Dropdown.Item>
                  ))
                }
                </DropdownButton>
              </InputGroup>
            </Form.Group>
          </Form>
        </center>
        &nbsp;
        <ListGroup>
        {
          tenants.map((val, index) => (
            <ListGroup.Item key={val.tenantName} action onClick={() => {tenantDetailsLink(val.tenantId)}}>
              <div className="ms-2 me-auto">
                <b>Tenant Id</b>: {val.tenantId}
                &nbsp; <b>Tenant Name:</b> {val.tenantName}
                <br></br>
                <b>Owner Name:</b> {val.ownerName}
                &nbsp; <b>Plan:</b> {val.plan}
                &nbsp; <b>Status:</b> {val.status}
              </div>
            </ListGroup.Item>
          ))
        }
        </ListGroup>
        <br></br>
        <center>
          { disablePrevButton
            ? null
            : <Button variant="primary" onClick={() => getPrev()}>Previous</Button>
          }
          &nbsp;&nbsp;
          { disableNextButton
            ? null
            : <Button variant="primary" onClick={() => getNext()}>Next</Button>
          }
        </center>
      </div>
    </div>
    </CustomLoader>
  );
}

export default TenantSearch;
