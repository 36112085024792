import MainHeader from '../../components/navigation/MainHeader';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './AuditUploads.module.css';
import { useEffect, useState } from 'react';
import { firebaseObserver } from '../../firebaseConfig';
import { useAuthContext } from "../../components/context/useAuthContext";
import { getDocument } from '../../components/database/platform/get';
import UserListComponent from '../../components/functional/user/UserListComponent';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';


const AuditUploads = () => {

  const params = useParams();
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const tenantId = params.tenantId;
  const [tenantName, setTenantName] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role === "SUPERADMIN") {
          setDisplayName(userAuthContext.displayName);
          setIsSuperAdmin(true);
        } else if (userAuthContext.role === "ADMIN"
          && userAuthContext.tenantId === params.tenantId) {
            setDisplayName(userAuthContext.displayName);
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
      console.log(userAuthContext);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        setIsSuperAdmin(data.claims?.role === "SUPERADMIN" ? true : false);
      });
    }
    searchTenant(params.tenantId);
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, params.tenantId, isSuperAdmin]);

  // DB call functions
  async function searchTenant(tenantId) {
    const tenantResult = await getDocument("tenants", tenantId);
    populateFields(tenantResult.data());
  }
  function populateFields(tenantDocument) {
    setTenantName(tenantDocument.tenantName);
  }


  return (
    <div className={classes.overlayWrapper}>
      <MainHeader />
      <center>
        <h3 style={{marginTop:15}}>
          Tenant
        </h3>
        <div className={classes.mainInputContainer}>
          <Form>
            <Form.Group className="mb-3" controlId="inputItem">
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="tenantId">Tenant Id:</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={tenantId}
                  disabled
                />
              </InputGroup>
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="tenantName">Tenant Name:</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={tenantName}
                  disabled
                />
              </InputGroup>
            </Form.Group>
          </Form>
          <Button
            variant='primary'
            onClick={() => navigate(`/tenantdetails/${tenantId}`)}
          >
            View Tenant Details
          </Button>
          &nbsp;
          <Button
            variant='primary'
            onClick={() =>  navigate(`/`)}
          >
            Exit
          </Button>
          <br></br>
          <br></br>
          <h5>Users</h5>
          <div style={{overflowY:"scroll", maxHeight:"300px"}}>
          <UserListComponent
            tenantId={tenantId}
            tenantName={tenantName}
            isSuperAdmin={isSuperAdmin}
            itemActionSwitch="AUDIT_UPLOAD"
          />
          </div>
        </div>
      </center>
    </div>
  );
};

export default AuditUploads;
