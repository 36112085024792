import { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Spinner } from "react-bootstrap";
import { insertSubcollectionDocument } from "../../database/tenant/insert";


const ShiftGroupButtonCreateModal = (props) => {

  const [show, setShow] = useState(false);
  const displayName = props.displayName;
  const tenantId = props.tenantId;
  const [validated, setValidated] = useState(false);

  const [shiftGroup, setShiftGroup] = useState("");
  const [shiftStart, setShiftStart] = useState("");
  const [workplaceName, setwWorkplaceName] = useState("");
  const [workplaceAddress, setWorkplaceAddress] = useState("");
  const [minimumShiftDuty, setMinimumShiftDuty] = useState(1);
  const [loaderState, setLoaderState] = useState(false);

  function handleClose () {
    setValidated(false);
    clearInputs();
    setShow(false);
  }
  function handleShow () {
    clearInputs();
    setValidated(false);
    setShow(true);
  }

  function shiftGroupListener(event) {
    setShiftGroup(event.target.value);
  }
  function shiftStartListener(event) {
    setShiftStart(event.target.value);
  }
  function workplaceNameListener(event) {
    setwWorkplaceName(event.target.value);
  }
  function workplaceAddressListener(event) {
    setWorkplaceAddress(event.target.value);
  }
  function minimumShiftDutyListener(event) {
    setMinimumShiftDuty(event.target.value);
  }

  function clearInputs() {
    setShiftGroup("");
    setShiftStart("");
    setWorkplaceAddress("");
    setwWorkplaceName("");
    setMinimumShiftDuty(1);
  }

  async function createHandler(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }

    const createPayload = {
      shiftGroup: shiftGroup,
      shiftStart: shiftStart,
      workplaceName: workplaceName,
      workplaceAddress: workplaceAddress,
      minimumShiftDuty: parseInt(minimumShiftDuty),
    }
    setLoaderState(true);
    await insertSubcollectionDocument("shiftGroups", tenantId, "shiftGroups", createPayload, displayName);

    setLoaderState(false);
    alert ('Successfully created a new shift Group.');
    window.location.reload(false);
    setShow(false);
  }


  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Create New Shift Group
      </Button>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Shift Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={createHandler}>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="shiftGroup">Shift Group Name:</InputGroup.Text>
            <Form.Control
              required
              type="text"
              value={shiftGroup}
              onChange={shiftGroupListener}
              placeholder="Name of the Shift Group"
            />
            <Form.Control.Feedback type="invalid">
              Must not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="shiftStart">Shift Start:</InputGroup.Text>
            <Form.Control
              required
              type="time"
              value={shiftStart}
              onChange={shiftStartListener}
            />
            <Form.Control.Feedback type="invalid">
              Must not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="workplaceName">Workplace Name:</InputGroup.Text>
            <Form.Control
              type="text"
              value={workplaceName}
              onChange={workplaceNameListener}
              placeholder="Name of the Workpace of shift Group (optional)"
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="workplaceAddress">Workplace Address:</InputGroup.Text>
            <Form.Control
              type="text"
              value={workplaceAddress}
              onChange={workplaceAddressListener}
              placeholder="Address of the Workpace of shift Group (optional)"
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="minimumShiftDuty">Minimum Number of Employees in Shift:</InputGroup.Text>
            <Form.Control
              required
              type="number"
              value={minimumShiftDuty}
              onChange={minimumShiftDutyListener}
            />
            <Form.Control.Feedback type="invalid">
              Must not be a number and not a blank.
            </Form.Control.Feedback>
            </InputGroup>

          </Form.Group>
          <center>
            { loaderState
              ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  center
                />
              : <Button type="submit">
                  Create Shift Group
                </Button>
            }
          </center>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default ShiftGroupButtonCreateModal

