import { useEffect, useState, Fragment } from 'react';
import Button from 'react-bootstrap/Button';


const HolidayManagementComponent = (props) => {

  const [groupedHoliday, setGroupedHoliday] = useState([]);

  useEffect(() => {
    groupHolidaysPerYear(props.holidays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function groupHolidaysPerYear(holidays) {
    const years = findDistinctYears(holidays);
    const groupedHolidayYears = {};
    for (const year of years) {
      groupedHolidayYears[year] = [];
      for (const holiday of holidays) {
        if (holiday.year === year) {
          groupedHolidayYears[year].push(holiday);
        }
      }
    }
    const groupedHolidaysIntoArr = Object.entries(groupedHolidayYears).map(([year, values]) => ({ year, values }));
    setGroupedHoliday(groupedHolidaysIntoArr);
  }

  function findDistinctYears(holidays) {
    return [...new Set(holidays.map(holiday => holiday.year))];
  }

  return (
    <Fragment>
    {
      groupedHoliday.map((holiday) => (
        <Fragment key={holiday.year}>
          <Button
            key={holiday.year}
            onClick={() => (props.onSelect(holiday.values))}>{holiday.year}
          </Button> &nbsp;
        </Fragment>
      ))
    }
    </Fragment>
  );
};


export default HolidayManagementComponent;
