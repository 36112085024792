import React, { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from 'react-bootstrap/ListGroup';
import ImagePopUpModal from "./ImagePopUpModal";
import SimpleMap from "./SimpleMap";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


const ImageAndMapModal = (props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let mapCoordinates = {};
  mapCoordinates.center = { lat:Number(props.latitude), lng:Number(props.longitude) };
  mapCoordinates.zoom = 16;
  mapCoordinates.markers = [{ lat:Number(props.latitude), lng:Number(props.longitude) }];

  const imageTooltipHelp = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click on the Image to enlarge.
    </Tooltip>
  );
  const mapTooltipHelp = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Hold "ctrl" and scroll up/down to zoom in/out of the map.
      The blue pin on the map indicates the location where the picture was taken.
    </Tooltip>
  );

  return (
    <Fragment>
      { props.listGroupMode
        ? <ListGroup.Item action onClick={handleShow}>
            <div key={props.dbRecord.imageUrl} className="ms-2 me-auto">
              {props.itemLabel}
            </div>
          </ListGroup.Item>
        : null
      }
      { props.buttonMode
        ? <Button variant="primary" onClick={handleShow}>
            Check Image and GPS Map
          </Button>
        : null
      }
      <Modal
        size="med"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.modalHeader}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OverlayTrigger
            placement="right-start"
            delay={{ show: 250, hide: 400 }}
            overlay={imageTooltipHelp}
          >
            <Button variant="success">Image Help</Button>
          </OverlayTrigger>
          <br></br>
          <div style={{textAlign: 'center'}}>
            <ImagePopUpModal
              imageUrl={props.dbRecord.imageUrl}
              imageUrlIcon={props.dbRecord.imageUrl}
            />
          </div>
          <OverlayTrigger
            placement="right-start"
            delay={{ show: 250, hide: 400 }}
            overlay={mapTooltipHelp}
          >
            <Button variant="success">Map Help</Button>
          </OverlayTrigger>
          <div style={{textAlign: 'center'}}>
            <SimpleMap
              center={mapCoordinates.center}
              zoom={mapCoordinates.zoom}
              markers={mapCoordinates.markers}
              width='300px' height='300px'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default ImageAndMapModal

