import LoadingOverlay from 'react-loading-overlay';
import BeatLoader from 'react-spinners/BeatLoader';
LoadingOverlay.propTypes = undefined;


function CustomLoader({ isActive, children }) {
  return (
    <LoadingOverlay
      active={isActive}
      spinner={<BeatLoader />}
      text="Loading..."
    >
      {children}
    </LoadingOverlay>
  )
}

export default CustomLoader;
