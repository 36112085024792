import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore/lite';
import ReactObserver from 'react-event-observer';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";

// Firebase config - DEV
export const firebaseConfig = {
  apiKey: "AIzaSyDKUns-y896gYoiyNTEZtvprVmo8USnfpc",
  authDomain: "dev-main-timekeeping.firebaseapp.com",
  projectId: "dev-main-timekeeping",
  storageBucket: "dev-main-timekeeping.appspot.com",
  messagingSenderId: "626596467811",
  appId: "1:626596467811:web:bda94003051cf9b1e1d456",
  measurementId: "G-V3799PB54H"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, process.env.REACT_APP_FIREBASE_REGION);
export const analytics = getAnalytics(app);

export const firebaseObserver = ReactObserver();
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    user.getIdTokenResult().then(function(data) {
      firebaseObserver.publish("userAuthContext",
        {
          displayName: user.displayName,
          role: data.claims?.role,
          tenantId: data.claims?.tenantId
        }
      );
    });
  } else {
    firebaseObserver.publish("userAuthContext",
      null
    );
  }
});
