import MainHeader from "../../components/navigation/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./UserLeaveManagement.module.css";
import { Fragment, useEffect, useState } from "react";
import CustomLoader from "../../components/ui-common/CustomLoader";
import { firebaseObserver } from "../../firebaseConfig";
import { useAuthContext } from "../../components/context/useAuthContext";
import { getSubcollectionDocument, listUserDocuments } from "../../components/database/tenant/get";
import LeavesListCreateActionModal from "../../components/functional/leaves/LeavesListCreateActionModal";
import ListGroup from "react-bootstrap/ListGroup";
import ShiftGroupListViewModal from "../../components/functional/shiftgroup/ShiftGroupListViewModal";
import { searchSubcollectionDocuments } from "../../components/database/tenant/searchSubcollection";


const UserLeaveManagement = () => {
  // User Auth Context
  const { user } = useAuthContext();

  // User list state
  const [userPofile, setUserProfile] = useState();
  const [leavesPerYear, setLeavesPerYear] = useState([]);
  // Tenant Id
  const [tenantId, setTenantId] = useState('');
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);
  // ShiftGroup state
  const [shiftGroup, setShiftGroup] = useState();
  // UserList state
  const [userList, setUserList] = useState();

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (!userAuthContext) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(userAuthContext.displayName);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        setTenantId(data.claims?.tenantId);
      });
    }
    if (displayName && tenantId) {
      getUserDetails(displayName);
    }
    return () => { firebaseObserver.unsubscribe('userAuthContext'); }
  },[displayName, tenantId]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  async function getUserDetails(displayName) {
    // Enable loader when request is being sent.
    setLoaderState(true);
    // console.log(tenantId);
    // console.log(displayName);
    const userRecord = await getSubcollectionDocument("users", tenantId, "users", "displayName", displayName);
    const user = userRecord.docs[0].data();
    const compensationRecords = await getSubcollectionDocument("compensation", tenantId, "compensation", "displayName", displayName) ?? [];
    let compensationList = [];
    for (const compensation of compensationRecords.docs) {
      compensationList.push(compensation.data());
    }
    compensationList.sort(function(a, b) {
      return b.year - a.year;
    });
    user.compensation = compensationList;

    const leaves = await listUserDocuments("leaves", tenantId, "leaves", displayName);
    // Grouping the leaves by leaveYear
    const groupedLeaves = leaves.reduce((result, leave) => {
      const { leaveYear } = leave;
      if (!result[leaveYear]) {
        result[leaveYear] = [];
      }
      result[leaveYear].push(leave);
      return result;
    }, {});

    // Get Shift Group info
    const shiftGroup = await getSubcollectionDocument("shiftGroups", tenantId, "shiftGroups", "shiftGroup", user.shiftGroup);
    // Get Shift Group members
    const users = await searchSubcollectionDocuments("users", tenantId, "users", [{key: "shiftGroup", inputValue: user.shiftGroup}], 100);

    setLeavesPerYear(groupedLeaves);
    setUserProfile(user);
    setLoaderState(false);
    setShiftGroup(shiftGroup.docs[0].data());
    setUserList(users.docs);
  }

  function reloadComponent(reloadSwitch) {
    if (reloadSwitch === true) {
      getUserDetails(displayName);
    }
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>File Leaves</h4>
        <ul className={classes.viewBox}>
        { userPofile ?
          <label>
            <b>User Name:</b> {userPofile.displayName}
            <br></br>
            <Fragment>
              { shiftGroup ?
                <ShiftGroupListViewModal
                  shiftGroup={shiftGroup}
                  usersList={userList}
                  displayName={displayName}
                  tenantId={tenantId}
                />
                : null
              }
            </Fragment>
            <br></br>
            <h4>Leaves</h4> <br></br>
            <Fragment>
              { userPofile.compensation
                ? <Fragment>
                  { userPofile.compensation.length > 0
                    ? <ListGroup>
                      { userPofile.compensation.map((compensationYear) =>
                        (
                          <Fragment key={compensationYear.year} >
                            <LeavesListCreateActionModal
                              compensation={compensationYear}
                              leaves={leavesPerYear[compensationYear.year]}
                              tenantId={tenantId}
                              displayName={displayName}
                              onSuccess={reloadComponent}
                            />
                          </Fragment>
                        ))
                      }
                      </ListGroup>
                    : <b>No Leaves Configured. Please contact HR</b>
                  }
                  </Fragment>
                : <b>No Leaves Configured. Please contact HR</b>
              }
            </Fragment>
          </label>
        : null }
        <br></br>
        </ul>
        </center>
      </div>
    </CustomLoader>
  );
}

export default UserLeaveManagement;
