import { useState } from 'react';
import firebase from "firebase/compat/app";
import AuthContext from './AuthContext';

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);

  firebase.auth().onAuthStateChanged(function(user) {
    setUser(user);
  });

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
    </AuthContext.Provider>
  );

};
