export const LeaveApprovalEnum = {
  PENDING_APPROVAL: "PENDING APPROVAL",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  NOT_SUBMITTED: "NOT SUBMITTED",
}

export const LeaveApprovalList = [
  "PENDING APPROVAL",
  "APPROVED",
  "REJECTED",
  "NOT SUBMITTED",
]
