import { firebaseObserver, storage } from "../../firebaseConfig";
import { Fragment, useEffect, useState, useRef } from "react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  updateMetadata,
  deleteObject
} from "firebase/storage";
import MainHeader from "../../components/navigation/MainHeader";
import classes from "./TimeInOut.module.css";
import { useNavigate } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from "../../components/context/useAuthContext";
import { GeoPoint, Timestamp } from "firebase/firestore/lite";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { insertSubcollectionDocument } from "../../components/database/tenant/insert";
import { searchSubcollectionCount } from "../../components/database/tenant/search";
import { getDocument } from "../../components/database/platform/get";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebaseConfig";
import CustomLoader from "../../components/ui-common/CustomLoader";


const TimeInOut = () => {
  // User Auth Context
  const { user } = useAuthContext();
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Tenant Id
  const [tenantId, setTenantId] = useState('');

  // State to store uploaded file uri for preview
  const [fileUri, setFileUri] = useState("");
  // State to store  progress
  const [percent, setPercent] = useState(0);
  // State to store uploaded file download url link
  const [url, setUploadedUrl] = useState('');
  const [loaderState, setLoaderState] = useState(false);

  // Get coordinates
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

  // Camera State
  const [cameraState, setCameraState] = useState(false);
  // Camera stream useRef
  const videoRef = useRef(null);
  // Camera open/close tracker (bug workaround)
  const videoFirstRender = useRef(0);
  // Camera not supported/error false = no error
  const [cameraInErrorStatus, setCameraInErrorStatus] = useState(false);

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (!userAuthContext) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(userAuthContext.displayName);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        setTenantId(data.claims?.tenantId);
      });
    }
    return () => { firebaseObserver.unsubscribe('userAuthContext'); }
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  async function getCurrentServerTime() {
    const getTimeNow = httpsCallable(functions, 'getTimeNow');
    const response = await getTimeNow({});
    return response.data;
  };

  async function checkTimeInOutLimit(minTime, maxTime) {
    // const today = new Date();
    // const maxTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);
    // const minTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
    const queryFilters = [
      {key: "displayName", comparisonOperator: "==", inputValue: displayName},
      {key: "dateTimeCreated", comparisonOperator: "<=", inputValue: maxTime},
      {key: "dateTimeCreated", comparisonOperator: ">=", inputValue: minTime}
    ];
    console.log(queryFilters);
    const currentTimeInOutCount = await searchSubcollectionCount("timeEntries", tenantId, "timeEntries", queryFilters);
    const tenantSettings = await getDocument("settings",tenantId);
    const timeInOutQuota = tenantSettings.data().timeEntryLimitPerUserDay;
    console.log(currentTimeInOutCount);
    console.log(timeInOutQuota);
    if (currentTimeInOutCount >= timeInOutQuota) {
      return true;
    }
    return false;
  };

  // Handle file upload event and update state
  async function handleChange(dataUri, isBase64, isManualUpload) {
    let imageFile;
    if (dataUri?.target?.files?.length > 0) {
        imageFile = Object.assign({}, dataUri);
    } else {
        imageFile = dataUri;
    }
    setLoaderState(true);
    const today = await getCurrentServerTime();
    console.log(today);
    const dateToday = new Date(today.dateToday);
    const minTimeJs = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate(), 0, 0, 0, 0);
    const maxTimeJs = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate(), 23, 59, 59, 999);
    const hitLimit = await checkTimeInOutLimit(minTimeJs, maxTimeJs);
    console.log(hitLimit);
    if (hitLimit) {
      alert('You have reached the daily maximum Time In/Out today. Please upgrade your plan or try again tomorrow.');
      setLoaderState(false);
      return;
    };
    let fileName;
    let processedFile;
    // If taken from mediaDevice camera, convert base64 to binary
    if (isBase64) {
        const randomFileId = uuidv4();
        const fileExtension = getFileExtension(imageFile.split(',')[0]);
        fileName = randomFileId.concat('.').concat(fileExtension);
        processedFile = dataURLtoFile(imageFile, fileName);
    } else {
        console.log(dataUri);
        processedFile = imageFile.target.files[0];
    }
    // Requery GPS coordinates
    getPosition();
    // Set timestamp
    // setTimestamp(DateTime.now().setZone('Asia/Manila').toFormat('dd-MMM-yyyy h:mm a'));
    const dateTimestamp = today.dateTimeStampToday;
    if (processedFile) {
      setFileUri(URL.createObjectURL(processedFile));
      setPercent(0);
      setUploadedUrl();
      await handleUpload(processedFile, dateTimestamp, isBase64, isManualUpload);
    }
    setLoaderState(false);
  }

  // Dynamic Progress to show completion icon.
  const ProgressComponent = () => {
    if (percent === 100) {
      return (
        <div>
          <br></br>
          <img src={process.env.PUBLIC_URL + 'images/complete_icon.png'} alt=""/>
          <br></br>
          <br></br>
        </div>
      )
    } else {
      return (percent + "% done");
    }
  }

  // Uploade Image display component
  const UploadedImage = () => {
    return (
      <img src={url} alt="" width="250" height="300"></img>
    )
  }

  // Action when you click upload
  async function handleUpload (file, dateTimestamp, isBase64, isManualUpload) {
    // Reset picture being uploaded.
    setUploadedUrl('');
    const storageRef = ref(storage, `/timeInScreenShots/${tenantId}/${displayName}/${dateTimestamp.split(" ")[0]}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      // update progress
      setPercent(percent);
      },
      (err) => alert(err.message),
      () => {
        // Get uploaded file url for perview
        getDownloadURL(uploadTask.snapshot.ref).then((urlResult) => {
          // Set download url hook
          setUploadedUrl(urlResult);
          // Update metadata to add gps coordinates to custom metadata
          const newMetadata = {
            customMetadata: {
              longitude: coords.longitude,
              latitude: coords.latitude,
              timestamp: dateTimestamp,
              downloadUrl: urlResult
            }
          };
          updateMetadata(storageRef, newMetadata)
            .then(async (metadataResult) => {
              if (!metadataResult) {
                const objectRef = ref(storageRef, file.name);
                // Delete the file
                deleteObject(objectRef).then(() => {
                  alert('Picture has no metadata. Please upload again.');
                }).catch((error) => {
                  alert(error);
                });
              } else {
                // Write to firstore database
                const screenshotDbData = {
                  dateTimeCreated: Timestamp.fromDate(new Date(dateTimestamp)),
                  timeInCoords: new GeoPoint(Number(coords.latitude), Number(coords.longitude)),
                  displayName: displayName,
                  imagePath: `/timeInScreenShots/${tenantId}/${displayName}/${dateTimestamp.split(" ")[0]}/${file.name}`,
                  imageUrl: metadataResult.customMetadata.downloadUrl,
                }
                await insertSubcollectionDocument("timeEntries", tenantId, "timeEntries", screenshotDbData, displayName);
              }
            }).catch((error) => {
              alert(error);
            });
        });
      }
    );

    // Reset fileURi after successful upload.
    if (isBase64) {
      videoFirstRender.current = 1;
    }
    else {
      setCameraState(false);
    }
  };

  async function validateStateVariables() {
    // Count list of image files (for checking if quota is reached)
    // const fileCounter = await getImageCount(displayName, dateTimestamp);
    let cameraState = true;
    if (!isGeolocationAvailable) {
      alert("Your browser does not support Geolocation, please use Google Chrome.");
      cameraState = false;
    }
    if (!isGeolocationEnabled) {
      alert("Reenable Geo location in your Phone.");
      window.location.reload(false);
      cameraState = false;
    }
    // if (fileCounter >= 10) {
    //   alert("You have reached Maximum file upload (10 pictures a day). Try again tomorrow.");
    //   cameraState = false;
    // }
    setCameraState(cameraState);
  }

  async function onTakePhoto (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
    // console.log(dataUri);
    await handleChange(dataUri, true, false);
    // console.log(videoFirstRender.current);
  }
  function onCameraError (error) {
    alert('Your device does not support modern HTML5 camera features. Switching to native HTML mode.')
    setCameraInErrorStatus(true);
    console.error('onCameraError', error);
  }
  function onCameraStart (stream) {
    videoRef.current = stream;
    console.log('onCameraStart');
    setPercent(0);
    setUploadedUrl('');
  }
  async function onCameraStop () {
    console.log('onCameraStop');
    if (videoFirstRender.current === 1) {
        setCameraState(false);
    }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }
  function getFileExtension(mime) {
    const mimeType = mime.match(/:(.*?);/)[1];
    return mimeType.split('/')[1];
  }
  async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
      <div className={classes.overlayWrapper}>
        <MainHeader />
        <center>
        <div className={classes.uploadBox}>
        { fileUri
          ? <Button
              variant="primary"
              onClick={()=>{window.location.reload(false)}}
            >
              Take Another Picture
            </Button>
          : <Fragment>
            <h2>Time in or out</h2>
            { cameraState
            ? <Fragment>
              { cameraInErrorStatus
                ? <div>
                  <label htmlFor="file-upload" className={classes.customFileUpload}>
                    Take a Picture
                  </label>
                  <input id="file-upload"
                    type="file"
                    style={{display:'none'}}
                    name="Take A picture"
                    onChange={ (event) => {handleChange(event, false, true);} }
                    accept="/image/*"
                  />
                </div>
                : <div>
                  <Camera
                  onTakePhoto = { (dataUri) => { onTakePhoto(dataUri); } }
                  onCameraError = { (error) => { onCameraError(error); } }
                  idealFacingMode = {FACING_MODES.USER}
                  idealResolution = {{width: 640, height: 480}}
                  imageType = {IMAGE_TYPES.JPG}
                  imageCompression = {0.97}
                  isMaxResolution = {false}
                  isImageMirror = {false}
                  isSilentMode = {true}
                  isDisplayStartCameraError = {true}
                  isFullscreen = {false}
                  sizeFactor = {1}
                  onCameraStart = { (stream) => { onCameraStart(stream); } }
                  onCameraStop = { () => { onCameraStop(); } }
                />
                </div>
              }
            </Fragment>
            :
            <Fragment>
            <Button
            variant="primary"
            onClick={validateStateVariables}
            style={{marginTop:10}}
            className={classes.buttonStyle}
            >
              Take a Picture
            </Button>
            </Fragment>
          }
          </Fragment>
        }
        { percent ? <ProgressComponent />: null }
        { url ? <UploadedImage /> : null }
        </div>
        </center>
      </div>
    </CustomLoader>
  );

}

export default TimeInOut;
