import * as React from "react";
import MainHeader from "../../components/navigation/MainHeader";
import classes from "./SuperAdminHelp.module.css";

const SuperAdminHelp = () => {

  return (
    <div>
      <MainHeader />
      <center>
      <h4 style={{marginTop:20}}>Super Admin User Guide</h4>
      <ul className={classes.viewBox}>
        <li> <h6>This is the Super Admin User Guide, if you need help using regular application features,
        the Regular User Help Guide can be found in:</h6>
        Go to Menu {">"} User {">"} Help
        <br></br><br></br>
        </li>
        <li> <h6>If you need help using ADMIN features,
        the Admin User Help Guide can be found in:</h6>
        Go to Menu {">"} Admin {">"} Help
        <br></br><br></br>
        </li>
        <li> <h6>All Super Admin users have access to Admin and regular User features.</h6>
        <br></br>
        </li>
        <li> <h6>How to reset your password:</h6>
        Go to Menu {">"} User {">"} User Profile
        Then click the "Reset My Password button"
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Manage Admins" button?</h6>
        This page will list all the users in the system. Clicking on the user will launch a popup that will
        allow you to promote them into an Admin or Super Admin User or demote them to regular User.
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Manage Leaves" button?</h6>
        This is the page where you can approve or reject leaves. Rejection requires a rejection message so the user will
        understand the reason for the rejection of their leave request.
        <br></br><br></br>
        </li>
        <li> <h6>What is the difference between HR Review and Status in Leaves?</h6>
        HR Review is approval/rejection by the ADMIN user or HR user. Status is the final approval/rejection by the SUPER ADMIN
        user or Management user (Mai or Joe).
        <br></br><br></br>
        </li>
      </ul>
      </center>
      <br></br>
      <br></br>
    </div>
  );
}

export default SuperAdminHelp;
