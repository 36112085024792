export const TenantStatusEnum = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  DISABLED: "DISABLED"
}

export const TenantStatusList = [
  "ACTIVE",
  "CANCELLED",
  "DISABLED",
  "*"
]
