import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./components/context/AuthProvider.js";
import App from "./App.js";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import SignOut from "./pages/SignOut.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserProfile from "./pages/User/UserProfile.js";
import ManageBroadcasts from "./pages/Admin/ManageBroadcasts.js";
import UserHelp from "./pages/User/UserHelp.js";
import AdminHelp from "./pages/Admin/AdminHelp.js";
import SuperAdminHelp from "./pages/SuperAdmin/SuperAdminHelp.js";
import CreateTenant from "./pages/SuperAdmin/Tenant/CreateTenant.js";
import TenantDetails from "./components/functional/tenant/TenantDetails.js";
import TenantSearch from "./components/functional/tenant/TenantSearch.js";
import UserManagement from "./components/functional/tenant/UserManagement.js";
import TimeInOut from "./pages/User/TimeInOut.js";
import ViewUploads from "./pages/User/ViewUploads.js";
import AuditUploads from "./pages/Admin/AuditUploads.js";
import TimeInOutReport from "./pages/Admin/TimeInOutReport.js";
import TimeInOutReportDetails from "./pages/Admin/TimeInOutReportDetails.js";
import ManageHolidaysPlatform from "./pages/SuperAdmin/Holiday/ManageHolidaysPlatform.js";
import ManageHolidaysTenant from "./pages/Admin/Holiday/ManageHolidaysTenant.js";
import ManageShiftGroups from "./pages/Admin/ShiftGroups/ManageShiftGroups.js";
import UserLeaveManagement from "./pages/User/UserLeaveManagement.js";
import ManageLeavesTenant from "./pages/Admin/Leave/ManageLeavesTenant.js";
// import FirstAdmin from "./pages/SuperAdmin/FirstSetupAdmin.js";


ReactDOM.render(
    <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/timeinout" element={<TimeInOut />} />
        <Route path="/viewuploads" element={<ViewUploads />} />
        <Route path="/fileleaves" element={<UserLeaveManagement />} />
        <Route path="/userhelp" element={<UserHelp />} />
        <Route path="/adminhelp" element={<AdminHelp />} />
        <Route path="/audituploads/:tenantId" element={<AuditUploads />} />
        <Route path="/managebroadcasts" element={<ManageBroadcasts />} />
        <Route path="/timeinoutreport/:tenantId" element={<TimeInOutReport />} />
        <Route path="/timeinoutreportdetails" element={<TimeInOutReportDetails />} />
        <Route path="/createtenant" element={<CreateTenant />} />
        <Route path="/searchtenant" element={<TenantSearch />} />
        <Route path="/tenantdetails/:tenantId" element={<TenantDetails />} />
        <Route path="/usermanagement/:tenantId" element={<UserManagement />} />
        <Route path="/manageshiftgroup/:tenantId" element={<ManageShiftGroups />} />
        <Route path="/holidaymanagement/:tenantId" element={<ManageHolidaysTenant />} />
        <Route path="/leavemanagement/:tenantId" element={<ManageLeavesTenant />} />
        <Route path="/holidaymanagement" element={<ManageHolidaysPlatform />} />
        <Route path="/superadminhelp" element={<SuperAdminHelp />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
    </BrowserRouter>,
  document.getElementById("root")
);

// Only use once during initial deployment for first admin user.
// <Route path="/secret" element={<FirstAdmin />} />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
