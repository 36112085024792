export const TenantPlanEnum = {
  FREE_TRIAL: "FREE_TRIAL",
  STANDARD: "STANDARD",
  PREMIUM: "PREMIUM"
}

export const TenantPlanList = [
  "FREE_TRIAL",
  "STANDARD",
  "PREMIUM"
]
