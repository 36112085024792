import { db } from '../../../firebaseConfig';
import { doc, collection, updateDoc, Timestamp } from 'firebase/firestore/lite';


export const updateDocument = async (collectionName, documentId, updateData, contextUser) => {
  const collectionRef = collection(db, collectionName);

  if (!contextUser) {
    contextUser = "root_user";
  }

  updateData.modifiedBy = contextUser;
  updateData.dateModified = Timestamp.now();

  try {
    await updateDoc(doc(collectionRef, documentId), updateData);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};
