import classes from './MainHeader.module.css';
import { useState } from 'react';
import firebase from 'firebase/compat/app';
import SideBar from './SideBar';


const MainHeader = () => {

  const [displayName, setDisplayName] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [displaySuperAdmin, setDisplaySuperAdmin] = useState(false);
  const [role, setRole] = useState('');
  const [displayAdmin, setDisplayAdmin] = useState(false);
  const [displayOperator, setDisplayOperator] = useState(false);

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      setDisplayName(user.displayName);
      user.getIdTokenResult().then(function(data) {
        setTenantId(data.claims?.tenantId);
        setRole(data.claims?.role);
        setDisplayAdmin(data.claims?.admin);
        setDisplaySuperAdmin(data.claims?.superadmin);
        setDisplayOperator(data.claims?.operator);
      });
    }
  });

  return (
    <header className={classes.header} >
      <SideBar
        displayName={displayName}
        role={role}
        tenantId={tenantId}
        isAdmin={displayAdmin}
        isSuperAdmin={displaySuperAdmin}
        isOperator={displayOperator}
      />
    </header>
  );
};

export default MainHeader;

