import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';


const UserListActionTimeReport = (props) => {

  const displayName = props.displayName;
  const tenantId = props.tenantId;
  const role = props.role;

  return (
      <Link key={`${displayName}UserListActionTimeReport`}
        to="/timeinoutreportdetails"
        style={{ textDecoration: 'none' }}
        state={
          {
            displayName: displayName,
            tenantId: tenantId
          }
        }
      >
        <ListGroup.Item action>
          <b>Display Name:</b> {displayName}
          &nbsp;
          <b>Role:</b> {role}
        </ListGroup.Item>
      </Link>
  );
};

export default UserListActionTimeReport;
