import { Fragment, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { functions } from "../../../firebaseConfig";
import { LockFill, UnlockFill } from "react-bootstrap-icons";
import { httpsCallable } from "firebase/functions";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const UsersListActionEditUserModal = (props) => {

  const tenantId = props.tenantId;
  const authUid = props.authUid;
  const displayName = props.displayName;
  const userRoleList = props.userRoleList;
  const shiftGroups = props.shiftGroups;
  const [role, setRole] = useState(props.role);
  const [email, setEmail] = useState(props.email);
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [shiftGroup, setShiftGroup] = useState(props.shiftGroup);
  const [dateStart, setDateStart] = useState(props.dateStart);
  const [compensation, setCompensation] = useState(props.compensation ?? []);

  const [show, setShow] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setCompensation(props.compensation ?? []);
  }, [props.compensation]);


  function handleClose() {
    setEnableEdit(false);
    setShow(false);
  }
  function handleShow() {
    setShow(true);
  }

  function emailListener(event) {
    setEmail(event.target.value);
  }
  function roleListener(role) {
    setRole(role);
  }
  function phoneNumberListener(event) {
    setPhoneNumber(event.target.value);
  }
  function firstNameListener(event) {
    setFirstName(event.target.value);
  }
  function lastNameListener(event) {
    setLastName(event.target.value);
  }
  function shiftGroupListener(value) {
    setShiftGroup(value);
    if (value === "Create New Shift Group") {
      navigate(`/manageshiftgroup/${tenantId}`);
    }
  }
  function dateStartListener(event) {
    setDateStart(event.target.value);
  }
  function compensationListener(compensationLeaveCredits, index) {
    const currentCompensationArray = [].concat(compensation);
    currentCompensationArray[index].leaveCredits = compensationLeaveCredits;
    setCompensation(currentCompensationArray);
  }

  async function addCompensationListener() {
    const currentCompensationArray = [].concat(compensation);
    let newCompYear;
    if (currentCompensationArray.length > 0) {
      const maxYear = currentCompensationArray.reduce((prev, current) => prev.year > current.year ? prev : current).year;
      newCompYear = parseInt(maxYear) + 1;
    } else {
      newCompYear = new Date().getFullYear().toString();
    }

    console.log(newCompYear);
    currentCompensationArray.push({
      displayName: displayName,
      year: newCompYear.toString(),
      leaveCredits: 0,
    });
    setCompensation(currentCompensationArray);
  }

  async function editClickHandler() {
    const editUser = httpsCallable(functions, 'editUser');
    setLoaderState(true);
    await editUser({
      tenantId: tenantId,
      authUid: authUid,
      displayName: displayName,
      email: email,
      role: role,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      shiftGroup: shiftGroup,
      dateStart: dateStart,
      compensation: compensation
    })
    .then((response) => {
      if (response.data?.displayName) {
        alert(`${response.data.displayName} has been edited successfully.`);
      } else {
        alert(response.data.errorInfo.message);
      }
    })
    .catch((error) => {
      alert(error);
    });
    setLoaderState(false);
    setEnableEdit(!enableEdit);
    setShow(false);
  }

  async function deleteClickHandler() {
    const deleteUser = httpsCallable(functions, 'deleteUser');
    setLoaderState(true);
    await deleteUser({
      tenantId: tenantId,
      authUid: authUid,
      displayName: displayName
    })
    .then((response) => {
      if (response.data?.displayName) {
        alert(`${response.data.displayName} has been deleted successfully.`);
        props.reloadComponent(response.data?.displayName);
      } else {
        alert(response.data.errorInfo.message);
      }
    })
    .catch((error) => {
      alert(error);
    });
    setLoaderState(false);
    setShow(false);
  }

  function toggleEdit() {
    if (enableEdit) {
      alert('You will lose the changes you made. Please click the "Save and Exit" Button next time if you want to save your changes');
    }
    setEnableEdit(!enableEdit);
  }

  return (
    <Fragment>
      <ListGroup.Item key={`${displayName}UsersListActionEditUserModal`} action onClick={handleShow}>
        <div className="ms-2 me-auto">
          <b>Display Name</b>: {displayName}
          &nbsp; <b>Role</b>: {role}
        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User&nbsp;
            { enableEdit
              ? <UnlockFill onClick={toggleEdit}/>
              : <LockFill onClick={toggleEdit}/>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { enableEdit
            ? <Form>
              <Form.Group className="mb-3" controlId="inputItem">
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="element">Display Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  disabled
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="email">Email</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={email}
                  onChange={emailListener}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="role">Role</InputGroup.Text>
                  <Dropdown
                    id="dropdown-button"
                    as={Form.Control}
                    value={role}
                    >
                  <Dropdown.Toggle variant="primary">
                    {role}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      userRoleList.map((val) => (
                        <Dropdown.Item
                          key={val}
                          onClick={() => {roleListener(val)}}>
                            {val}
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                  </Dropdown>
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="phoneNumber">Phone Number</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={phoneNumber}
                  onChange={phoneNumberListener}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="firstName">First Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={firstNameListener}
                />
                <InputGroup.Text id="lastName">Last Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={lastNameListener}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="dateStart">Date Start:</InputGroup.Text>
                <Form.Control
                  type="date"
                  value={dateStart}
                  onChange={dateStartListener}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="shiftGroup">Shift Group:</InputGroup.Text>
                  <Dropdown
                      required
                      id="dropdown-button"
                      as={Form.Control}
                      value={shiftGroup}
                      >
                    <Dropdown.Toggle variant="primary">
                      {shiftGroup}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        shiftGroups.map((shiftGroup) => (
                          <Dropdown.Item
                            key={shiftGroup.shiftGroup}
                            onClick={() => {shiftGroupListener(shiftGroup.shiftGroup)}}>
                              {shiftGroup.shiftGroup}
                          </Dropdown.Item>
                        ))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </InputGroup>

              { compensation.length > 0
                ? <Fragment>
                  <h4>Leaves</h4>
                  { compensation.map((compensation, index) =>
                    (
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="leaveCredits">Year {compensation.year} Leave Credits:</InputGroup.Text>
                        <Form.Control
                          type="number"
                          value={compensation.leaveCredits}
                          onChange={(event) => {compensationListener(event.target.value, index)}}
                        />
                      </InputGroup>
                    ))
                  }
                  <Button
                    variant="primary"
                    onClick={addCompensationListener}
                  >
                    Add Leave for Succeeding Year
                  </Button>
                  </Fragment>
                : <Fragment>
                    <h4>No leaves configured</h4>
                    <Button
                      variant="primary"
                      onClick={addCompensationListener}
                    >
                      Add Leaves
                    </Button>
                  </Fragment>
              }

              </Form.Group>
              </Form>
            : <Form>
            <Form.Group className="mb-3" controlId="inputItem">
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="displayName">Display Name</InputGroup.Text>
              <Form.Control
                type="text"
                value={displayName}
                disabled
              />
              </InputGroup>

              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="email">Email</InputGroup.Text>
              <Form.Control
                type="text"
                value={email}
                disabled
              />
              </InputGroup>
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="role">Role</InputGroup.Text>
              <Form.Control
                type="text"
                value={role}
                disabled
              />
              </InputGroup>

              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="phoneNumber">Phone Number</InputGroup.Text>
              <Form.Control
                type="text"
                value={phoneNumber}
                disabled
              />
              </InputGroup>

              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="firstName">First Name</InputGroup.Text>
              <Form.Control
                type="text"
                value={firstName}
                disabled
              />
              <InputGroup.Text id="lastName">Last Name</InputGroup.Text>
              <Form.Control
                type="text"
                value={lastName}
                disabled
              />
              </InputGroup>

              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="dateStart">Date Start:</InputGroup.Text>
              <Form.Control
                type="date"
                value={dateStart}
                disabled
              />
              </InputGroup>

              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="shiftGroup">Shift Group:</InputGroup.Text>
              <Form.Control
                required
                type="text"
                value={shiftGroup}
                disabled
              />
              </InputGroup>

              { compensation.length > 0
                ? <Fragment>
                  <h4>Leaves</h4>
                  { compensation.map((compensation) =>
                    (
                      <InputGroup key={compensation.year} className="mb-1" size="sm">
                      <InputGroup.Text id="leaveCredits">Year {compensation.year} Leave Credits:</InputGroup.Text>
                        <Form.Control
                          type="number"
                          value={compensation.leaveCredits}
                          disabled
                        />
                      </InputGroup>
                    ))
                  }
                  </Fragment>
                : <Fragment>
                    <h4>No leaves configured</h4>
                    Edit user details to add leaves.
                  </Fragment>
              }

            </Form.Group>
            </Form>
          }
          <center>
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                center
              />
            : <Fragment>
                <Button
                  variant="primary"
                  onClick={deleteClickHandler}
                >
                  Delete User
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  onClick={editClickHandler}
                >
                  Save and Exit
                </Button>
              </Fragment>
          }
          </center>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default UsersListActionEditUserModal

