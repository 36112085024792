import MainHeader from '../../../components/navigation/MainHeader';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './ManageHolidaysTenant.module.css';
import { useEffect, useState } from 'react';
import CustomLoader from '../../../components/ui-common/CustomLoader';
import { firebaseObserver } from '../../../firebaseConfig';
import { useAuthContext } from "../../../components/context/useAuthContext";
import HolidayManagementComponent from '../../../components/functional/holiday/HolidayManagementComponent';
import HolidayListEditModal from '../../../components/functional/holiday/HolidayListEditModal';
import { Fragment } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { listSubcollectionDocuments } from '../../../components/database/tenant/get';
import HolidayButtonCreateModal from '../../../components/functional/holiday/HolidayButtonCreateModal';


const ManageHolidaysTenant = () => {

  const params = useParams();
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [loaderState, setLoaderState] = useState(false);
  const tenantId = params.tenantId;

  const [holidays, setHolidays] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role === "SUPERADMIN") {
          setDisplayName(userAuthContext.displayName);
        } else if (userAuthContext.role === "ADMIN"
          && userAuthContext.tenantId === tenantId) {
            setDisplayName(userAuthContext.displayName);
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
      console.log(userAuthContext);
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        if (data.claims?.role !== "SUPERADMIN" && data.claims?.role !== "ADMIN") {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    getHolidays(tenantId);
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, params.tenantId]);

  async function getHolidays(tenantId) {
    setLoaderState(true);
    const holidaysResult = await listSubcollectionDocuments("holidays", tenantId, "holidays", "holidayDate", "holidayDate", "asc");
    setHolidays(holidaysResult);
    setLoaderState(false);
  }

  function onSelectYear(holidaysInYear) {
    setSelectedYear(holidaysInYear);
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center>
        <h3 style={{marginTop:15}}>Manage Holidays</h3>
        <div className={classes.mainInputContainer}>
          <HolidayButtonCreateModal
            displayName={displayName}
            tenantId={tenantId}
          />
          <br></br>
          <br></br>
          <HolidayManagementComponent
            holidays={holidays}
            displayName={displayName}
            onSelect={onSelectYear}
          />
        </div>
        <br></br>
        {
          selectedYear.length > 0
          ? <div className={classes.mainInputContainer}>
              <ListGroup>
              {
                selectedYear.map((holiday) => (
                  <Fragment key={holiday.holidayName}>
                    <HolidayListEditModal
                      holiday={holiday}
                      displayName={displayName}
                      tenantId={tenantId}
                    />
                  </Fragment>
                ))
              }
              </ListGroup>
            </div>
          : null
        }
      </center>
    </div>
    </CustomLoader>
  );
};

export default ManageHolidaysTenant;
