export const UserRolesEnum = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  USER: "USER"
}

export const UserRolesList = [
  "SUPERADMIN",
  "ADMIN",
  "USER"
]
