import { Fragment, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { functions } from "../../../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { Spinner } from "react-bootstrap";
import { LeaveApprovalEnum } from "../../../models/leaves";
import { DateTime } from "luxon";


const LeavesListCreateActionModal = (props) => {

  const tenantId = props.tenantId;
  const displayName = props.displayName;
  const compensation = props.compensation;
  const [leaves, setLeaves] = useState(props.leaves ?? []);

  const [show, setShow] = useState(false);
  const [loaderStates, setLoaderStates] = useState([false]);

  useEffect(() => {
    setLeaves(props.leaves ?? []);
  }, [props.leaves]);


  function handleClose() {
    setShow(false);
  }
  function handleShow() {
    setShow(true);
  }

  function leaveDateListener(leaveDate, index) {
    const currentLeavesArray = [].concat(leaves);
    currentLeavesArray[index].leaveDate = leaveDate;
    currentLeavesArray[index].leaveYear = leaveDate.split("-")[0];
    setLeaves(currentLeavesArray);
  }
  function reasonListener(reason, index) {
    const currentLeavesArray = [].concat(leaves);
    currentLeavesArray[index].reason = reason;
    setLeaves(currentLeavesArray);
  }

  async function addLeaveHandler() {
    const currentLeavesArray = [].concat(leaves);
    currentLeavesArray.push({
      tenantId: tenantId,
      displayName: displayName,
      leaveDate: DateTime.local().toFormat('yyyy-MM-dd'),
      leaveYear: DateTime.local().toFormat('yyyy'),
      reason: "",
      status: LeaveApprovalEnum.NOT_SUBMITTED
    });
    setLeaves(currentLeavesArray);
    const tempLoaderStates = [].concat(loaderStates);
    tempLoaderStates.push(false);
    setLoaderStates(tempLoaderStates);
  }
  async function submitLeaveHandler(leave, index) {
    if (compensation.year !== leave.leaveYear){
      alert(`Please file a leave on the correct year: ${compensation.year}`);
      return;
    }
    if (!leave.reason || leave.reason?.length < 3) {
      alert(`Please add a reason for the leave and make it longer than 3 characters.`);
      return;
    }
    leave.tenantId = tenantId;
    const fileLeave = httpsCallable(functions, 'fileLeave');
    let tempLoaderStates = [].concat(loaderStates);
    tempLoaderStates[index] = true;
    setLoaderStates(tempLoaderStates);

    await fileLeave(leave).then((response) => {
      if (response?.data?.errorInfo) {
        tempLoaderStates = [].concat(loaderStates);
        tempLoaderStates[index] = false;
        setLoaderStates(tempLoaderStates);
        alert(response.data.errorInfo.message);
        return '';
      } else {
        props.onSuccess(true);
        alert(`Leave has been filed successfully.`);
      }
    })
    .catch((error) => {
      alert(error);
    });

    tempLoaderStates = [].concat(loaderStates);
    tempLoaderStates[index] = false;
    setLoaderStates(tempLoaderStates);
  }

  function leaveUsed(leaves) {
    const excludedLeaveStatuses = ["REJECTED", "NOT SUBMITTED"]
    const filteredLeavesArray = leaves.filter(function(leave) {
      return !excludedLeaveStatuses.includes(leave.status);
    });
    console.log(filteredLeavesArray);
    return filteredLeavesArray.length;
  }

  return (
    <Fragment>
      <ListGroup.Item key={`${displayName}UsersListActionEditUserModal`} action onClick={handleShow}>
        <div className="ms-2 me-auto">
          <b>Year</b>: {compensation.year} &nbsp;
          <b>Total Leaves</b>: {compensation.leaveCredits} &nbsp;
          <b>Remaining Leaves</b>: {compensation.leaveCredits - leaveUsed(leaves)} &nbsp;
          <b>Used Leaves</b>: {leaveUsed(leaves)}
        </div>
      </ListGroup.Item>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Leaves for Year {compensation.year}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { leaves.length > 0
            ? <Fragment>
              { leaves.map((leave, arrayIndex) =>
                (
                  <Fragment key={arrayIndex}>
                    <Form>
                    <Form.Group className="mb-3" controlId="inputItem">
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="element">Leave Date</InputGroup.Text>
                      <Form.Control
                        type="date"
                        value={leave.leaveDate}
                        onChange={(event) => leaveDateListener(event.target.value, arrayIndex)}
                        disabled={leave.status === LeaveApprovalEnum.NOT_SUBMITTED ? false : true}
                      />
                      <InputGroup.Text id="element">Status</InputGroup.Text>
                      <Form.Control
                        type="text"
                        disabled
                        value={leave.status}
                      />
                      </InputGroup>
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="element">Reason</InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={leave.reason}
                        onChange={(event) => reasonListener(event.target.value, arrayIndex)}
                        disabled={leave.status === LeaveApprovalEnum.NOT_SUBMITTED ? false : true}
                      />
                      </InputGroup>
                      { loaderStates[arrayIndex]
                        ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            center
                          />
                        : <Fragment>
                          { leave.status === LeaveApprovalEnum.NOT_SUBMITTED
                            ? <Button
                                variant="primary"
                                onClick={() => submitLeaveHandler(leave, arrayIndex)}
                              >
                                Submit
                              </Button>
                            : null
                          }
                        </Fragment>
                      }
                    </Form.Group>
                    </Form>
                  </Fragment>
                ))
              }
              </Fragment>
            : <Fragment>
                <h4>No leaves filed.</h4>
              </Fragment>
          }
          <Button
            variant="primary"
            onClick={addLeaveHandler}
          >
            Create Leave Request
          </Button>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default LeavesListCreateActionModal

