import { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { Spinner } from "react-bootstrap";
import { updateDocumentSubcollection } from "../../database/tenant/update";


const ShiftGroupListEditModal = (props) => {

  const [show, setShow] = useState(false);
  const displayName = props.displayName;
  const tenantId = props.tenantId;

  const docId = props.shiftGroup.docId;
  const [shiftGroup, setShiftGroup] = useState(props.shiftGroup.shiftGroup);
  const [shiftStart, setShiftStart] = useState(props.shiftGroup.shiftStart);
  const [workplaceName, setwWorkplaceName] = useState(props.shiftGroup.workplaceName);
  const [workplaceAddress, setWorkplaceAddress] = useState(props.shiftGroup.workplaceAddress);
  const [minimumShiftDuty, setMinimumShiftDuty] = useState(props.shiftGroup.minimumShiftDuty);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loaderState, setLoaderState] = useState(false);

  function shiftGroupListener(event) {
    setShiftGroup(event.target.value);
  }
  function shiftStartListener(event) {
    setShiftStart(event.target.value);
  }
  function workplaceNameListener(event) {
    setwWorkplaceName(event.target.value);
  }
  function workplaceAddressListener(event) {
    setWorkplaceAddress(event.target.value);
  }
  function minimumShiftDutyListener(event) {
    setMinimumShiftDuty(event.target.value);
  }

  async function saveHandler() {
    const updatePayload = {
      shiftGroup: shiftGroup,
      shiftStart: shiftStart,
      workplaceName: workplaceName,
      workplaceAddress: workplaceAddress,
      minimumShiftDuty: parseInt(minimumShiftDuty),
    }
    setLoaderState(true);
    await updateDocumentSubcollection("shiftGroups", tenantId, "shiftGroups", docId, updatePayload, displayName);
    setLoaderState(false);
    alert ('Successfully updated the shift group.');
    setShow(false);
  }


  return (
    <Fragment>
      <ListGroup.Item key={`${shiftGroup}`} action onClick={handleShow}>
        <div className="ms-2 me-auto">
          <b>{shiftGroup}</b> &nbsp;
          {shiftStart}
        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit {shiftGroup}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="shiftGroup">Shift Group Name:</InputGroup.Text>
            <Form.Control
              required
              type="text"
              value={shiftGroup}
              onChange={shiftGroupListener}
              placeholder="Name of the Shift Group"
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="shiftStart">Shift Start:</InputGroup.Text>
            <Form.Control
              required
              type="time"
              value={shiftStart}
              onChange={shiftStartListener}
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="workplaceName">Workplace Name:</InputGroup.Text>
            <Form.Control
              type="text"
              value={workplaceName}
              onChange={workplaceNameListener}
              placeholder="Name of the Workpace of shift Group (optional)"
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="workplaceAddress">Workplace Address:</InputGroup.Text>
            <Form.Control
              type="text"
              value={workplaceAddress}
              onChange={workplaceAddressListener}
              placeholder="Address of the Workpace of shift Group (optional)"
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="minimumShiftDuty">Minimum Number of Employees in Shift:</InputGroup.Text>
            <Form.Control
              required
              type="number"
              value={minimumShiftDuty}
              onChange={minimumShiftDutyListener}
            />
            </InputGroup>

          </Form.Group>
          </Form>
          <center>
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                center
              />
            : <Button variant="primary" onClick={saveHandler}>
                Save
              </Button>
          }
          </center>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default ShiftGroupListEditModal

