import { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Spinner } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { HolidayTypeEnum, HolidayTypeList } from "../../../models/holiday";
import { listDocuments } from "../../database/platform/get";
import { insertSubcollectionDocument } from "../../database/tenant/insert";
import { insertDocument } from "../../database/platform/insert";


const HolidayButtonCreateModal = (props) => {

  const [show, setShow] = useState(false);
  const displayName = props.displayName;
  const tenantId = props.tenantId;
  const [validated, setValidated] = useState(false);
  const [submitAction, setSubmitAction] = useState("");

  const [holidayCode, setHolidayCode] = useState("");
  const [holidayDate, setHolidayDate] = useState("");
  const [holidayName, setHolidayName] = useState("");
  const [type, setType] = useState(HolidayTypeEnum.REGULAR);
  const [year, setYear] = useState("");

  function handleClose () {
    setValidated(false);
    clearInputs();
    setShow(false);
  }
  function handleShow () {
    clearInputs();
    setValidated(false);
    setShow(true);
  }
  const [loaderState, setLoaderState] = useState(false);

  function holidayCodeListener(event) {
    setHolidayCode(event.target.value);
  }
  function holidayDateListener(event) {
    setYear(parseInt(event.target.value.split('-')[0]));
    setHolidayDate(event.target.value);
  }
  function holidayNameListener(event) {
    setHolidayName(event.target.value);
  }
  function typeListener(value) {
    setType(value);
  }

  function clearInputs() {
    setHolidayCode("");
    setHolidayDate("");
    setHolidayName("");
    setType(HolidayTypeEnum.REGULAR);
    setYear("");
  }

  async function submitHandler(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    if (submitAction === "createOnly") {
      await createHandler();
    } else if (submitAction === "createAndCascade") {
      await createAndCascadeHandler();
    }
    setValidated(false);
  }

  async function createHandler() {
    const createPayload = {
      holidayCode: holidayCode,
      holidayName: holidayName,
      holidayDate: holidayDate,
      type: type,
      year: year
    }
    setLoaderState(true);
    if (tenantId) {
      await insertSubcollectionDocument("holidays", tenantId, "holidays", createPayload, displayName, holidayCode);
    } else {
      await insertDocument("defaultHolidays", "holidayCode", createPayload, displayName, holidayCode);
    }
    setLoaderState(false);
    alert ('Successfully created the holiday.');
    window.location.reload(false);
    setShow(false);
  }

  async function createAndCascadeHandler() {
    const createPayload = {
      holidayCode: holidayCode,
      holidayName: holidayName,
      holidayDate: holidayDate,
      type: type,
      year: year
    }
    setLoaderState(true);
    await createHandler();
    const tenantsList = await listDocuments("tenants", "tenantId", "asc");
    for (const tenant of tenantsList) {
      await insertSubcollectionDocument("holidays", tenant.tenantId, "holidays", createPayload, displayName, holidayCode);
    }
    setLoaderState(false);
    alert ('Successfully cascaded the holiday to all tenants.');
    setShow(false);
  }

  return (
    <Fragment>
      <Button key={`${holidayName}`} variant="primary" onClick={handleShow}>
        Create New Holiday
      </Button>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Holiday</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="holidayCode">Holiday Code:</InputGroup.Text>
            <Form.Control
              required
              type="text"
              value={holidayCode}
              onChange={holidayCodeListener}
              placeholder="holidayName+Year e.g. newyear2023"
            />
            <Form.Control.Feedback type="invalid">
              Must not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="holidayName">Holiday Name:</InputGroup.Text>
            <Form.Control
            required
              type="text"
              value={holidayName}
              onChange={holidayNameListener}
              placeholder="Holiday Name for Display Purposes e.g. New Year"
            />
            <Form.Control.Feedback type="invalid">
              Must not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="holidayDate">Holiday Date:</InputGroup.Text>
            <Form.Control
              required
              type="date"
              value={holidayDate}
              onChange={holidayDateListener}
              placeholder="Date of Holiday"
            />
            <Form.Control.Feedback type="invalid">
              Must not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="type">Holiday Type:</InputGroup.Text>
              <DropdownButton id="type" title={type} style={{marginRight:15}}>
                {
                  HolidayTypeList.map((holidayType) => (
                    <Dropdown.Item key={`${holidayName}${holidayType}`} onClick={() => (typeListener(holidayType))}>{holidayType}</Dropdown.Item>
                  ))
                }
              </DropdownButton>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="year">Year:</InputGroup.Text>
            <Form.Control
              type="text"
              value={year}
              disabled
            />
            </InputGroup>

          </Form.Group>
          <center>
            { loaderState
              ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  center
                />
              : <Fragment>
                { tenantId
                  ? <Button type="submit" onClick={() => setSubmitAction("createOnly")}>
                      Create Holiday
                    </Button>
                  : <Fragment>
                    <Button type="submit" onClick={() => setSubmitAction("createAndCascade")}>
                      Create Holiday And Cascade to All Tenants
                    </Button>
                    <br></br>
                    <br></br>
                    <Button type="submit" onClick={() => setSubmitAction("createOnly")}>
                      Create Holiday Only
                    </Button>
                    </Fragment>
                }
                </Fragment>
            }
          </center>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default HolidayButtonCreateModal

