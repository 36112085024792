import { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { Spinner } from "react-bootstrap";
import { LeaveApprovalEnum } from "../../../models/leaves";
import { updateDocumentSubcollection } from "../../database/tenant/update";


const LeavesListActionApproveModal = (props) => {

  const tenantId = props.tenantId;
  const displayName = props.displayName;
  const leave = props.leave;

  const [show, setShow] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  function handleClose() {
    setShow(false);
  }
  function handleShow() {
    setShow(true);
  }

  async function approveHandler() {
    setLoaderState(true);
    const approveData = {
      status: LeaveApprovalEnum.APPROVED,
      reviewedBy: displayName
    };
    await updateDocumentSubcollection("leaves", tenantId, "leaves", leave.docId, approveData, displayName);
    setLoaderState(false);
    alert(`Leave Approved Successfully.`);
    props.onSuccess(true);
    handleClose();
  }
  async function rejectHandler() {
    setLoaderState(true);
    const rejectData = {
      status: LeaveApprovalEnum.REJECTED,
      reviewedBy: displayName
    };
    await updateDocumentSubcollection("leaves", tenantId, "leaves", leave.docId, rejectData, displayName);
    setLoaderState(false);
    alert(`Leave Rejected Successfully.`);
    props.onSuccess(true);
    handleClose();
  }


  return (
    <Fragment>
      <ListGroup.Item action onClick={handleShow}>
        <div className="ms-2 me-auto">
          <b>Leave Date</b>: {leave.leaveDate} &nbsp;
          <b>Status</b>: {leave.status} &nbsp;
          <br></br>
          <b>DisplayName</b>: {leave.displayName}
        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Review Leaves for Approval
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">Leave Date:</InputGroup.Text>
            <Form.Control
              value={leave.leaveDate}
              disabled
            />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">Display Name:</InputGroup.Text>
            <Form.Control
              value={leave.displayName}
              disabled
            />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">Status:</InputGroup.Text>
            <Form.Control
              value={leave.status}
              disabled
            />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">Reason:</InputGroup.Text>
            <Form.Control
              value={leave.reason}
              disabled
            />
            </InputGroup>
            { leave.approvedBy
              ? <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="element">Approved By:</InputGroup.Text>
                <Form.Control
                  value={leave.approvedBy}
                  disabled
                />
                </InputGroup>
              : null
            }
          </Form.Group>
          </Form>
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                center
              />
            : <Fragment>
                <Button
                  variant="primary"
                  onClick={approveHandler}
                >
                  APPROVE
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  onClick={rejectHandler}
                >
                  REJECT
                </Button>
              </Fragment>
          }
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default LeavesListActionApproveModal

