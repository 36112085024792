import { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { updateDocument } from "../../database/platform/update";
import { Spinner } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { HolidayTypeList } from "../../../models/holiday";
import { updateDocumentSubcollection } from "../../database/tenant/update";
import { listDocuments } from "../../database/platform/get";
import { insertSubcollectionDocument } from "../../database/tenant/insert";


const HolidayListEditModal = (props) => {

  const [show, setShow] = useState(false);
  const displayName = props.displayName;
  const tenantId = props.tenantId;

  const docId = props.holiday.docId;
  const [holidayCode, setHolidayCode] = useState(props.holiday.holidayCode);
  const [holidayDate, setHolidayDate] = useState(props.holiday.holidayDate);
  const [holidayName, setHolidayName] = useState(props.holiday.holidayName);
  const [type, setType] = useState(props.holiday.type);
  const [year, setYear] = useState(props.holiday.year);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loaderState, setLoaderState] = useState(false);

  function holidayCodeListener(event) {
    setHolidayCode(event.target.value);
  }
  function holidayDateListener(event) {
    setYear(event.target.value.split('-')[0]);
    setHolidayDate(event.target.value);
  }
  function holidayNameListener(event) {
    setHolidayName(event.target.value);
  }
  function typeListener(value) {
    setType(value);
  }

  async function saveHandler() {
    const updatePayload = {
      holidayCode: holidayCode,
      holidayName: holidayName,
      holidayDate: holidayDate,
      type: type,
      year: year
    }
    setLoaderState(true);
    if (tenantId) {
      await updateDocumentSubcollection("holidays", tenantId, "holidays", docId, updatePayload, displayName);
    } else {
      await updateDocument("defaultHolidays", docId, updatePayload, displayName);
    }
    setLoaderState(false);
    alert ('Successfully updated the holiday.');
    setShow(false);
  }

  async function cascadeAllTenantsHandler() {
    const updatePayload = {
      holidayCode: holidayCode,
      holidayName: holidayName,
      holidayDate: holidayDate,
      type: type,
      year: year
    }
    setLoaderState(true);
    const tenantsList = await listDocuments("tenants", "tenantId", "asc");
    for (const tenant of tenantsList) {
      await insertSubcollectionDocument("holidays", tenant.tenantId, "holidays", updatePayload, displayName, docId);
    }
    setLoaderState(false);
    alert ('Successfully cascaded the holiday to all tenants.');
    setShow(false);
  }

  return (
    <Fragment>
      <ListGroup.Item key={`${holidayName}`} action onClick={handleShow}>
        <div className="ms-2 me-auto">
          <b>{holidayName}</b> &nbsp;
          {holidayDate} &nbsp;
          {type}
        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit {holidayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="holidayCode">Holiday Code:</InputGroup.Text>
            <Form.Control
              type="text"
              value={holidayCode}
              onChange={holidayCodeListener}
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="holidayName">Holiday Name:</InputGroup.Text>
            <Form.Control
              type="text"
              value={holidayName}
              onChange={holidayNameListener}
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="holidayDate">Holiday Date:</InputGroup.Text>
            <Form.Control
              type="date"
              value={holidayDate}
              onChange={holidayDateListener}
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="type">Holiday Type:</InputGroup.Text>
              <DropdownButton id="type" title={type} style={{marginRight:15}}>
                {
                  HolidayTypeList.map((holidayType) => (
                    <Dropdown.Item key={`${holidayName}${holidayType}`} onClick={() => (typeListener(holidayType))}>{holidayType}</Dropdown.Item>
                  ))
                }
              </DropdownButton>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="year">Year:</InputGroup.Text>
            <Form.Control
              type="text"
              value={year}
              disabled
            />
            </InputGroup>

          </Form.Group>
          </Form>
          <center>
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                center
              />
            : <Fragment>
              { tenantId
                ? <Button variant="primary" onClick={saveHandler}>
                    Save
                  </Button>
                : <Fragment>
                  <Button variant="primary" onClick={cascadeAllTenantsHandler}>
                    Cascade to All Tenants
                  </Button>
                  &nbsp;
                  &nbsp;
                  <Button variant="primary" onClick={saveHandler}>
                    Save
                  </Button>
                  </Fragment>
              }
              </Fragment>
          }
          </center>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default HolidayListEditModal

