import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainHeader from "../../components/navigation/MainHeader";
import { firebaseObserver } from "../../firebaseConfig";
import classes from "./TimeInOutReportDetails.module.css";
import { useAuthContext } from "../../components/context/useAuthContext";
import { useNavigate } from "react-router-dom";
import { getSubcollectionDocument, listUserDocuments } from "../../components/database/tenant/get";
import { ListGroup } from "react-bootstrap";
import { DateTime } from "luxon";
import ImageAndMapModal from "../../components/ui-common/ImageAndMapModal";
import { Fragment } from "react";


const TimeInOutReportDetails = () => {

  const { user } = useAuthContext();
  const location = useLocation();
  const [displayName, setDisplayName] = useState(location.state?.displayName ?? user?.displayName);
  const [tenantId, setTenantId] = useState(location.state?.tenantId);
  const navigate = useNavigate()

  const [origDbRecords, setOrigDbRecords] = useState([]);
  // state for getting list of images
  const [monthList, setMonthList] = useState([]);
  const [monthSelected, setMonthSelected] = useState('');
  // state for setting dateFilter
  const [daySelected, setDaySelected] = useState('');
  const [dayList, setDayList] = useState([]);

  const [timeInStatus, setTimeInStatus] = useState('');
  const [shiftStartFormatted, setShiftStartFormatted] = useState('');
  const [shiftStart, setShiftStart] = useState('');
  const [workDuration, setWorkDuration] = useState('');
  const [timeInRecord, setTimeInRecord] = useState({});
  const [formattedTimeIn, setFormattedTimeIn] = useState({});
  const [formattedTimeOut, setFormattedTimeOut] = useState({});
  const [timeOutRecord, setTimeOutRecord] = useState();

  // Get data from previous route/page
  // Get window size on screen resize
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (!userAuthContext) {
        alert('Unauthorized access.');
        navigate('/');
      }
      if (!displayName) {
        setDisplayName(userAuthContext.displayName);
      }
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        if (!tenantId) {
          setTenantId(data.claims?.tenantId);
        }
      });
    }
    if (tenantId && displayName) {
      getUserTimeEntries(tenantId, displayName);
    }

    console.log(displayName);
    console.log(tenantId);

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.state, displayName, tenantId]);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  async function getUserTimeEntries(tenantId, displayName) {
    const user = await getSubcollectionDocument("users", tenantId, "users", "displayName", displayName);
    const shiftStart = user.docs[0].data().shiftStart;
    setShiftStart(shiftStart);
    setShiftStartFormatted(convert12Hour(shiftStart));
    const timeEntries = await listUserDocuments("timeEntries", tenantId, "timeEntries", displayName, "dateTimeCreated", "desc");
    const {months, days} = getMonthsDays(timeEntries);
    const origMonthList = formatMonth(sortDate(months));
    setMonthList(origMonthList);
    const origDayList = formatDay(sortDate(days));
    setOrigDbRecords(processDbRecordDates(timeEntries, origDayList, shiftStart));
  }

  function processDbRecordDates(arr, daylist, shiftStart) {
    // will return in this format

    // Append parsed days to db record
    const tempRecords = [];
    arr.forEach(record => {
      const dayMonthYear = record.dateTimeCreated.toDate().toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).split('/');
      const month = dayMonthYear[0];
      const day = dayMonthYear[1];
      const year = dayMonthYear[2];
      tempRecords.push({
        rawDay: year + '-' + month + '-' + day,
        rawMonth: year + '-' + month,
        ...record
      });
    });
    // Go through the db records and combine records for the same day.
    const processedRecords = [];
    for (const day of daylist){
      const dayRecord = {
        rawDay: day.rawDay,
        formattedDay: (new Date(day.rawDay)).toLocaleString("en-US", {year: "numeric", month: "long", day: "numeric"}),
        rawMonth: day.rawMonth,
        formattedMonth: (new Date(day.rawMonth)).toLocaleString("en-US", {year: "numeric", month: "long"}),
        shiftStart: shiftStart,
        timeRecords: []
      };
      for (const record of tempRecords) {
        if (day.rawDay === record.rawDay) {
          dayRecord.timeRecords.push({
            timeCreated: record.dateTimeCreated,
            coordinates: record.timeInCoords,
            imageUrl: record.imageUrl
          });
        }
      }
      processedRecords.push(dayRecord);
    };
    // Get Max amd Min date for shift calculation
    for (const processedRecord of processedRecords) {
      processedRecord.timeRecords.sort(function(a, b) {
        const dateA = a.timeCreated.toDate();
        const dateB = b.timeCreated.toDate();
        return dateA - dateB;
      });
      processedRecord.timeInRecord = processedRecord.timeRecords[0];
      if (processedRecord.timeRecords.length > 1) {
        const lastIndex = processedRecord.timeRecords.length - 1;
        processedRecord.timeOutRecord = processedRecord.timeRecords[lastIndex];
      } else {
        processedRecord.timeOutRecord = {};
      }
    }
    // console.log(processedRecords);
    return processedRecords;
  }

  function formatDay(arr) {
    const formattedDays = [];
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long"
    };
    arr.forEach(record => {
      const day = record.split('-');
      formattedDays.push({
        formattedDay: (new Date(record)).toLocaleString("en-US", options),
        rawDay: record,
        rawMonth: day[0] + '-' + day[1]
      });
    })
    return formattedDays;
  }

  function formatMonth(arr) {
    const formattedMonths = [];
    const options = {
      year: "numeric",
      month: "long",
    };
    arr.forEach(record => {
      formattedMonths.push({
        formattedMonth: (new Date(record + '-01')).toLocaleString("en-US", options),
        rawMonth: record
      });
    })
    return formattedMonths;
  }

  function sortDate(arr) {
    arr.sort(function(a, b) {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB - dateA;
    });
    return arr;
  }

  function getMonthsDays(arr) {
    const tempMonthsList = [];
    const tempDaysList = [];
    arr.forEach(record => {
      if (record.dateTimeCreated) {
        const monthYear = record.dateTimeCreated.toDate().toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
        }).split('/').reverse().join('-');
        tempMonthsList.push(monthYear);
        const dayMonthYear = record.dateTimeCreated.toDate().toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }).split('/');
        const month = dayMonthYear[0];
        const day = dayMonthYear[1];
        const year = dayMonthYear[2];
        tempDaysList.push(year + '-' + month + '-' + day);
      }
    });
    return {
      months: removeDuplicates(tempMonthsList),
      days: removeDuplicates(tempDaysList)
    }
  }

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  function convert12Hour(hoursmins) {
    const [hours, minutes] = hoursmins.split(':');
    let time12 = (hours % 12 || 12) + ':' + minutes;
    time12 += hours >= 12 ? ' PM' : ' AM';
    return time12;
  }
  function convert12HourRaw(date) {
    if (!date) {
      return null;
    }
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toDate().toLocaleString('en-US', options);
  }
  function convert24HourRaw(date) {
    if (!date) {
      return null;
    }
    const options = { hour: 'numeric', minute: 'numeric', hour12: false };
    return date.toDate().toLocaleString('en-US', options);
  }
  function selectMonthHandler(monthYear) {
    const filteredDays = origDbRecords.filter(day => day.rawMonth === monthYear.rawMonth);
    setMonthSelected(monthYear.formattedMonth);
    setDayList(filteredDays);
  }
  function selectDayHandler(date) {
    const filteredDays = origDbRecords.filter(day => day.rawDay === date.rawDay);
    setDaySelected(date.formattedDay);
    setTimeInRecord(filteredDays[0].timeInRecord);
    setFormattedTimeIn(convert12HourRaw(filteredDays[0].timeInRecord.timeCreated));
    setTimeOutRecord(filteredDays[0].timeOutRecord);
    setFormattedTimeOut(convert12HourRaw(filteredDays[0].timeOutRecord?.timeCreated) ?? "No Time Out");
    if (convert24HourRaw(filteredDays[0].timeInRecord.timeCreated) < shiftStart) {
      setTimeInStatus('NOT LATE');
    } else {
      setTimeInStatus('LATE');
    }
    if (filteredDays[0].timeOutRecord?.timeCreated) {
      // Calculate the duration between the two dates and extract the hours and minutes
      const timeOut = DateTime.fromJSDate(filteredDays[0].timeOutRecord?.timeCreated.toDate());
      const timeIn = DateTime.fromJSDate(filteredDays[0].timeInRecord?.timeCreated.toDate());
      const duration = timeOut.diff(timeIn);
      const hours = Math.floor(duration.as('hours'));
      const minutes = duration.as('minutes') % 60;
      setWorkDuration(`${hours} hours and ${minutes} minutes`);
    } else {
      setWorkDuration('No Time Out');
    }
    console.log(filteredDays);
  }


  return (
    <div>
    <MainHeader />
      <center><br></br>
      <h2>{displayName}'s Time In/Out Report </h2>
      <div className={classes.mainContainer}>
      { monthSelected || windowSize.innerWidth < 900
        ? null
        : <div className={classes.hiddenContainerItems}></div>
      }
        <div className={classes.containerItems}>
          <h3>Months</h3>
          <div style={{overflowY:"scroll", maxHeight:"350px"}}>
            <ListGroup>
            {
              monthList.map((month) => (
                <ListGroup.Item key={month.rawMonth} action onClick={() => {selectMonthHandler(month)}}>
                  {month.formattedMonth}
                </ListGroup.Item>
              ))
            }
            </ListGroup>
          </div>
        </div>
        { monthSelected
          ? <div className={classes.containerItems}>
            <h3>{monthSelected}</h3>
            { dayList
              ? <div style={{overflowY:"scroll", maxHeight:"350px"}}>
                  <ListGroup>
                    {
                      dayList.map((date) => (
                        <ListGroup.Item
                          key={date.rawDay}
                          action onClick={() => {selectDayHandler(date)}}
                        >
                          {date.formattedDay}
                        </ListGroup.Item>
                      ))
                    }
                  </ListGroup>
                </div>
              : null
            }
            </div>
          : null
        }
        { daySelected
          ? <div className={classes.containerItems}>
            <h3>{daySelected}</h3>
              <b>Shift Start:</b> {shiftStartFormatted}
              <br></br>
              <b>Time In Status:</b> {timeInStatus}
              <br></br>
              <b>Work Duration:</b> {workDuration}
              <br></br>
              <br></br>
              <b>Time In:</b> {formattedTimeIn}
              <br></br>
              <ImageAndMapModal
                itemLabel={timeInRecord.timeCreated.toDate().toLocaleString('en-Us',{
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                })}
                modalHeader={timeInRecord.timeCreated.toDate().toLocaleString('en-Us',{
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                })}
                latitude={timeInRecord.coordinates.latitude}
                longitude={timeInRecord.coordinates.longitude}
                dbRecord={timeInRecord}
                buttonMode={true}
              />
              <br></br>
              <br></br>
              { timeOutRecord.timeCreated
                ? <Fragment>
                    <b>Time Out:</b> {formattedTimeOut}
                    <br></br>
                    <ImageAndMapModal
                      itemLabel={timeOutRecord.timeCreated.toDate().toLocaleString('en-Us',{
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })}
                      modalHeader={timeOutRecord.timeCreated.toDate().toLocaleString('en-Us',{
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })}
                      latitude={timeOutRecord.coordinates.latitude}
                      longitude={timeOutRecord.coordinates.longitude}
                      dbRecord={timeOutRecord}
                      buttonMode={true}
                    />
                    <br></br>
                  </Fragment>
                : null
              }
            </div>
          : null
        }
      <br></br>
      </div>
      </center>
    </div>
    );
};

export default TimeInOutReportDetails;

