import { db } from '../../../firebaseConfig';
import { doc, collection, updateDoc, Timestamp } from 'firebase/firestore/lite';


export const updateDocumentSubcollection = async (collectionName, tenantId, subCollectionName, documentId, updateData, contextUser) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  updateData.modifiedBy = contextUser;
  updateData.dateModified = Timestamp.now();

  try {
    await updateDoc(doc(subcollectionRef, documentId), updateData);
  } catch (error) {
    console.log(`DB error encountered: ${error}`);
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};
