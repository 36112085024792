import { db } from '../../../firebaseConfig';
import { collection, getDocs, doc, setDoc, query, where, Timestamp } from 'firebase/firestore/lite';


export const insertDocument = async (collectionName, uniqueKey, insertData, contextUser, documentId) => {

  if (insertData[uniqueKey] === "BLANK") {
    alert('BLANK is a system reserved word, do not use it as a record unique key.')
    return false;
  }

  if (!contextUser) {
    contextUser = "root_user";
  }

  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef,
    where(uniqueKey,'==', insertData[uniqueKey]));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  if (docsSnapshot.docs.length > 0) {
    alert('Record already exists, please use a different name or edit the existing one.')
    return false;
  }
  insertData.modifiedBy = contextUser;
  insertData.dateModified = Timestamp.now();

  try {
    if (documentId) {
      await setDoc(doc(collectionRef, documentId), insertData);
    } else {
      await setDoc(doc(collectionRef), insertData);
    }
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};
