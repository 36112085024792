import MainHeader from '../../../components/navigation/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from './ManageHolidaysPlatform.module.css';
import { useEffect, useState } from 'react';
import CustomLoader from '../../../components/ui-common/CustomLoader';
import { firebaseObserver } from '../../../firebaseConfig';
import { useAuthContext } from "../../../components/context/useAuthContext";
import { listDocuments } from '../../../components/database/platform/get';
import HolidayManagementComponent from '../../../components/functional/holiday/HolidayManagementComponent';
import HolidayListEditModal from '../../../components/functional/holiday/HolidayListEditModal';
import { Fragment } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import HolidayButtonCreateModal from '../../../components/functional/holiday/HolidayButtonCreateModal';


const ManageHolidaysPlatform = () => {

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [loaderState, setLoaderState] = useState(false);

  const [holidays, setHolidays] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (userAuthContext) {
        if (userAuthContext.role !== "SUPERADMIN") {
          alert('Unauthorized access.');
          navigate('/');
        }
        setDisplayName(userAuthContext.displayName);
      }
      else {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        if (data.claims?.role !== "SUPERADMIN") {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    getHolidays();
    return () => {
      firebaseObserver.unsubscribe('userAuthContext');
    }
  }, [navigate, user]);

  async function getHolidays() {
    setLoaderState(true);
    const holidaysResult = await listDocuments("defaultHolidays", "holidayDate","asc");
    setHolidays(holidaysResult);
    setLoaderState(false);
  }

  function onSelectYear(holidaysInYear) {
    setSelectedYear(holidaysInYear);
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center>
        <h3 style={{marginTop:15}}>Manage Holidays</h3>
        <div className={classes.mainInputContainer}>
          <HolidayButtonCreateModal
            displayName={displayName}
          />
          <br></br>
          <br></br>
          <HolidayManagementComponent
            holidays={holidays}
            displayName={displayName}
            onSelect={onSelectYear}
          />
        </div>
        <br></br>
        {
          selectedYear.length > 0
          ? <div className={classes.mainInputContainer}>
              <ListGroup>
              {
                selectedYear.map((holiday) => (
                  <Fragment key={holiday.holidayName}>
                    <HolidayListEditModal
                      holiday={holiday}
                      displayName={displayName}
                    />
                  </Fragment>
                ))
              }
              </ListGroup>
            </div>
          : null
        }
      </center>
    </div>
    </CustomLoader>
  );
};

export default ManageHolidaysPlatform;
