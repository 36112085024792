import { Fragment, useEffect, useState } from 'react';
import { useAuthContext } from "../../context/useAuthContext";
import ListGroup from 'react-bootstrap/ListGroup';
import { listSubcollectionDocuments } from '../../database/tenant/get';
import { UserRolesList } from '../../../models/user-roles';
import UsersListActionEditUserModal from './UsersListActionEditUserModal';
import UserListActionAuditUpload from './UserListActionAuditUpload';
import UserListActionTimeReport from './UserListActionTimeReport';


const UserListComponent = (props) => {

  const itemActionSwitch = props.itemActionSwitch;
  const { user } = useAuthContext();
  const displayName = user?.displayName;
  const shiftGroups = props.shiftGroups;
  const [userList, setUserList] = useState([]);
  const [userRoleList, setUserRoleList] = useState(UserRolesList);

  useEffect(() => {
    searchUsers(props.tenantId);
    if (!props.isSuperAdmin) {
      setUserRoleList(['ADMIN','USER']);
    } else {
      setUserRoleList(UserRolesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, props.tenantId, props.reload, props.isSuperAdmin]);

  // DB call functions
  async function searchUsers(tenantId) {
    const users = await listSubcollectionDocuments("users", tenantId, "users", "displayName", "displayName", "asc") ?? [];
    setUserList(users);
    const compensations = await listSubcollectionDocuments("compensation", tenantId, "compensation", "displayName", "displayName", "asc") ?? [];
    const mergedUsers = users.map((user) => {
      const compensation = compensations.filter((compensation) => compensation.displayName === user.displayName);
      user.compensation = compensation;
      return user;
    });
    console.log(mergedUsers);
    setUserList(mergedUsers);
  }
  function reloadComponent(displayName) {
    searchUsers(props.tenantId);
    const tempList = userList;
    const indexToDelete = tempList.findIndex((user) => {
      return user.displayname === displayName;
    });
    if (indexToDelete !== -1) {
      tempList.splice(indexToDelete, 1);
    }
    setUserList(tempList);
  }


  return (
    <Fragment>
    <ListGroup>
      {
        userList.map((user) => (
          <Fragment key={user.authUid}>
          { itemActionSwitch === "USER_EDIT"
            ? <UsersListActionEditUserModal
                key="USER_EDIT"
                tenantId={props.tenantId}
                authUid={user.authUid}
                userRoleList={userRoleList}
                reloadComponent={reloadComponent}
                displayName={user.displayName}
                role={user.role}
                email={user.email}
                phoneNumber={user.phoneNumber}
                firstName={user.firstName}
                lastName={user.lastName}
                shiftStart={user.shiftStart}
                dateStart={user.dateStart}
                compensation={user.compensation}
                shiftGroup={user.shiftGroup}
                shiftGroups={shiftGroups}
              />
            : null
          }
          { itemActionSwitch === "AUDIT_UPLOAD"
            ? <UserListActionAuditUpload
                key="AUDIT_UPLOAD"
                tenantId={props.tenantId}
                displayName={user.displayName}
                role={user.role}
              />
            : null
          }
          { itemActionSwitch === "TIME_REPORT"
            ? <UserListActionTimeReport
                key="TIME_REPORT"
                tenantId={props.tenantId}
                displayName={user.displayName}
                role={user.role}
              />
            : null
          }
          </Fragment>
        ))
      }
    </ListGroup>
    </Fragment>
  );
};

export default UserListComponent;
