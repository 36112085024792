import { db } from '../../../firebaseConfig';
import { collection, doc, addDoc } from 'firebase/firestore/lite';


export const createSubcollection = async (collectionName, tenantId, subCollectionName) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  try {
    await addDoc(subcollectionRef, {});
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};
