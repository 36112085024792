import { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';


const ShiftGroupListViewModal = (props) => {

  const [show, setShow] = useState(false);
  const shiftGroupRecord = props.shiftGroup;
  const usersList = props.usersList ?? [];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <Fragment>
      <div className="ms-2 me-auto">
        <b>Shift Group: </b>
        <Button variant="primary" onClick={handleShow} >
          {shiftGroupRecord.shiftGroup}
        </Button>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Shift Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="shiftGroup">Shift Group Name:</InputGroup.Text>
            <Form.Control
              required
              type="text"
              value={shiftGroupRecord.shiftGroup}
              placeholder="Name of the Shift Group"
              disabled
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="shiftStart">Shift Start:</InputGroup.Text>
            <Form.Control
              required
              type="time"
              value={shiftGroupRecord.shiftStart}
              disabled
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="workplaceName">Workplace Name:</InputGroup.Text>
            <Form.Control
              type="text"
              value={shiftGroupRecord.workplaceName}
              placeholder="Name of the Workpace of shift Group (optional)"
              disabled
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="workplaceAddress">Workplace Address:</InputGroup.Text>
            <Form.Control
              type="text"
              value={shiftGroupRecord.workplaceAddress}
              placeholder="Address of the Workpace of shift Group (optional)"
              disabled
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="minimumShiftDuty">Minimum Number of Employees in Shift:</InputGroup.Text>
            <Form.Control
              required
              type="number"
              value={shiftGroupRecord.minimumShiftDuty}
              disabled
            />
            </InputGroup>

          </Form.Group>
          </Form>
          <h5> Team members in the same group</h5>
          <div style={{overflowY:"scroll", maxHeight:"300px"}}>
            <ListGroup>
            { usersList.map((user, arrayIndex) => (
              <ListGroup.Item key={`${user.displayName}`}>
              <b>Display Name:</b> {user.displayName} &nbsp;
              <b>First Name:</b> {user.firstName} &nbsp;
              <b>Last Name:</b> {user.lastName}
              </ListGroup.Item>
              ))
            }
            </ListGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default ShiftGroupListViewModal

