import { db } from '../../../firebaseConfig';
import { getDocs, collection, doc, query, where, orderBy, limit, limitToLast, startAfter, endBefore } from 'firebase/firestore/lite';


export const getSubcollectionDocument = async (collectionName, tenantId, subCollectionName, uniqueKey, searchValue) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  const q = query(subcollectionRef,
    where(uniqueKey,'==',searchValue));

  try {
    const docs = await getDocs(q);
    return docs;
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }
};

export const listUserDocuments = async (collectionName, tenantId, subCollectionName, displayName, sortKey, sortDirection) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  let q;
  if (!sortKey) {
    q =  query(
      subcollectionRef,
      where("displayName", "==", displayName)
    );
  } else {
    q =  query(
      subcollectionRef,
      where("displayName", "==", displayName),
      orderBy(sortKey, sortDirection)
    );
  }

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      const data = doc.data();
      data.docId = doc.docId;
      tempList.push(data);
    }
  });
  return tempList;
};

export const listSubcollectionDocuments = async (collectionName, tenantId, subCollectionName, sortKey, returnKey, sortDirection) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  const q =  query(subcollectionRef, orderBy(sortKey, sortDirection));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      const data = doc.data();
      tempList.push({
        [returnKey]:data[returnKey],
        docId: doc.id,
        ...data
      });
    }
  });
  return tempList;
};

export const listSubcollectionDocumentsWithPagination = async (collectionName, tenantId, subCollectionName, searchLimit, sortKey, sortDirection) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  const q =  query(subcollectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push({docId: doc.id, ...doc.data()});
    }
  });

  if (docsSnapshot.docs.length < 1) {
    return;
  }

  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];
  let isNextAvailable;

  const nextq =  query(subcollectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection),
    startAfter(lastVisibleDocument));

  let docsSnapshotNextAhead;
  try {
    docsSnapshotNextAhead = await getDocs(nextq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }
  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: tempList,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable
  })
};

export const listSubcollectionDocumentsWithPaginationNext = async (collectionName, tenantId, subCollectionName, lastVisible, searchLimit, sortKey, sortDirection) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  const q =  query(subcollectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection),
    startAfter(lastVisible));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }

  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push({docId: doc.id, ...doc.data()});
    }
  });

  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];
  let isNextAvailable;

  const nextq =  query(subcollectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection,
      startAfter(lastVisibleDocument)));

  let docsSnapshotNextAhead;
  try {
    docsSnapshotNextAhead = await getDocs(nextq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }
  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: tempList,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable
  })
};

export const listDocumentsWithPaginationPrev = async (collectionName, tenantId, subCollectionName, firstVisible, searchLimit, sortKey, sortDirection) => {

  const parentDocRef = doc(db, collectionName, tenantId);
  const subcollectionRef = collection(parentDocRef, subCollectionName);

  const q =  query(subcollectionRef,
    limitToLast(searchLimit),
    orderBy(sortKey, sortDirection),
    endBefore(firstVisible));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }

  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push({docId: doc.id, ...doc.data()});
    }
  });

  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];
  let isPrevAvailable;

  const prevq =  query(subcollectionRef,
    limitToLast(searchLimit),
    orderBy(sortKey, sortDirection),
    endBefore(firstVisibleDocument));

  let docsSnapshotPrevBefore;
  try {
    docsSnapshotPrevBefore = await getDocs(prevq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }

  if (docsSnapshotPrevBefore.docs.length < 1) {
    isPrevAvailable = false;
  } else {
    isPrevAvailable = true;
  }

  return ({
    docs: tempList,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isPrevAvailable: isPrevAvailable
  })
};
