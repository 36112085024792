import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainHeader from "../../components/navigation/MainHeader";
import { firebaseObserver } from "../../firebaseConfig";
import classes from "./ViewUploads.module.css";
import { useAuthContext } from "../../components/context/useAuthContext";
import { useNavigate } from "react-router-dom";
import { listUserDocuments } from "../../components/database/tenant/get";
import { ListGroup } from "react-bootstrap";
import ImageAndMapModal from "../../components/ui-common/ImageAndMapModal";


const ViewUploads = () => {

  const { user } = useAuthContext();
  const location = useLocation();
  const [displayName, setDisplayName] = useState(location.state?.displayName ?? user?.displayName);
  const [tenantId, setTenantId] = useState(location.state?.tenantId);
  const navigate = useNavigate()

  const [origDbRecord, setOrigDbRecord] = useState([]);
  // state for getting list of images
  const [monthList, setMonthList] = useState([]);
  const [monthSelected, setMonthSelected] = useState('');
  // state for setting dateFilter
  const [dateList, setDateList] = useState([]);
  const [origDateList, setOrigDateList] = useState([]);
  const [daySelected, setDaySelected] = useState('');
  const [dayList, setDayList] = useState([]);

  // Get data from previous route/page
  // Get window size on screen resize
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    firebaseObserver.subscribe('userAuthContext', userAuthContext => {
      if (!userAuthContext) {
        alert('Unauthorized access.');
        navigate('/');
      }
      if (!displayName) {
        setDisplayName(userAuthContext.displayName);
      }
    });
    if (user) {
        user.getIdTokenResult().then(function(data) {
        if (!tenantId) {
          setTenantId(data.claims?.tenantId);
        }
      });
    }
    if (tenantId && displayName) {
      getUserTimeEntries(tenantId, displayName);
    }

    console.log(displayName);
    console.log(tenantId);

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.state, displayName, tenantId]);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  async function getUserTimeEntries(tenantId, displayName) {
    const timeEntries = await listUserDocuments("timeEntries", tenantId, "timeEntries", displayName, "dateTimeCreated", "desc");
    setOrigDbRecord(parseDbRecordDates(timeEntries));
    const {months, days} = getMonthsDays(timeEntries);
    setMonthList(formatMonth(sortDate(months)));
    setOrigDateList(formatDay(sortDate(days)));
    console.log(origDateList);
    console.log(origDbRecord);
  }

  function parseDbRecordDates(arr) {
    const tempRecords = [];
    arr.forEach(record => {
      const dayMonthYear = record.dateTimeCreated.toDate().toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).split('/');
      const month = dayMonthYear[0];
      const day = dayMonthYear[1];
      const year = dayMonthYear[2];
      tempRecords.push({rawDay: year + '-' + month + '-' + day, ...record});
    });
    return tempRecords;
  }

  function formatDay(arr) {
    const formattedDays = [];
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long"
    };
    arr.forEach(record => {
      const day = record.split('-');
      formattedDays.push({
        formattedDay: (new Date(record)).toLocaleString("en-US", options),
        rawDay: record,
        rawMonthYear: day[0] + '-' + day[1]
      });
    })
    return formattedDays;
  }

  function formatMonth(arr) {
    const formattedMonths = [];
    const options = {
      year: "numeric",
      month: "long",
    };
    arr.forEach(record => {
      formattedMonths.push({
        formattedMonth: (new Date(record + '-01')).toLocaleString("en-US", options),
        rawMonth: record
      });
    })
    return formattedMonths;
  }

  function sortDate(arr) {
    arr.sort(function(a, b) {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB - dateA;
    });
    return arr;
  }

  function getMonthsDays(arr) {
    const tempMonthsList = [];
    const tempDaysList = [];
    arr.forEach(record => {
      if (record.dateTimeCreated) {
        const monthYear = record.dateTimeCreated.toDate().toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
        }).split('/').reverse().join('-');
        tempMonthsList.push(monthYear);
        const dayMonthYear = record.dateTimeCreated.toDate().toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }).split('/');
        const month = dayMonthYear[0];
        const day = dayMonthYear[1];
        const year = dayMonthYear[2];
        tempDaysList.push(year + '-' + month + '-' + day);
      }
    });
    return {months: removeDuplicates(tempMonthsList), days: removeDuplicates(tempDaysList)}
  }

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  function selectMonthHandler(monthYear) {
    const filteredDays = origDateList.filter(day => day.rawMonthYear === monthYear.rawMonth);
    setMonthSelected(monthYear.formattedMonth);
    setDateList(filteredDays);
  }

  function selectDayHandler(date) {
    const filteredDays = origDbRecord.filter(day => day.rawDay === date.rawDay);
    setDaySelected(date.formattedDay);
    setDayList(filteredDays);
    console.log(filteredDays);
  }

  return (
    <div>
    <MainHeader />
      <center><br></br>
      <h2>{displayName}'s Time In/Out Entries </h2>
      <div className={classes.mainContainer}>
      { monthSelected || windowSize.innerWidth < 900
        ? null
        : <div className={classes.hiddenContainerItems}></div>
      }
        <div className={classes.containerItems}>
          <h3>Months</h3>
          <div style={{overflowY:"scroll", maxHeight:"350px"}}>
            <ListGroup>
            {
              monthList.map((month) => (
                <ListGroup.Item key={month.rawMonth} action onClick={() => {selectMonthHandler(month)}}>
                  {month.formattedMonth}
                </ListGroup.Item>
              ))
            }
            </ListGroup>
          </div>
        </div>
        { monthSelected
          ? <div className={classes.containerItems}>
            <h3>{monthSelected}</h3>
            { dateList
              ? <div style={{overflowY:"scroll", maxHeight:"350px"}}>
                  <ListGroup>
                    {
                      dateList.map((date) => (
                        <ListGroup.Item
                          key={date.formattedDay}
                          action onClick={() => {selectDayHandler(date)}}
                        >
                          {date.formattedDay}
                        </ListGroup.Item>
                      ))
                    }
                  </ListGroup>
                </div>
              : null
            }
            </div>
          : null
        }
        { daySelected
          ? <div className={classes.containerItems}>
            <h3>{daySelected}</h3>
            { dayList
              ? <div style={{overflowY:"scroll", maxHeight:"350px"}}>
                  <ListGroup>
                    {
                      dayList.map((date) => (
                        <ImageAndMapModal
                          itemLabel={date.dateTimeCreated.toDate().toLocaleString('en-Us',{
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                          })}
                          modalHeader={date.dateTimeCreated.toDate().toLocaleString('en-Us',{
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                          })}
                          latitude={date.timeInCoords.latitude}
                          longitude={date.timeInCoords.longitude}
                          dbRecord={date}
                          listGroupMode={true}
                        />
                      ))
                    }
                  </ListGroup>
                </div>
              : null
            }
            </div>
          : null
        }
      <br></br>
      </div>
      </center>
    </div>
    );
};

export default ViewUploads;

